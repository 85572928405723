import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AccountType, OrgStatus } from '@constants/generic';
import { OrgPreApprovedStage, Spinner } from '@components';
import { HELP, PAGE_NOT_FOUND } from '@constants/routes';

import ThankyouPage from './ThankyouPage';
import ChoosePlanPage from './ChoosePlanPage';

const ApprovalGuard = ({ children }: any) => {
  const { currentOrg, isOrgListLoading, appConfig } = useSelector(
    (state: any) => state.user
  );

  const { pathname } = useLocation();

  if ([PAGE_NOT_FOUND, HELP].includes(pathname)) return <div>{children}</div>;

  if (isOrgListLoading)
    return (
      <div className="absolute left-1/2 top-1/2 flex size-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
        <Spinner />
      </div>
    );

  if (currentOrg?.status === OrgStatus.APPROVED && !currentOrg.has_plan)
    return <ChoosePlanPage name={currentOrg?.name || ''} />;

  if (
    currentOrg?.status === OrgStatus.PENDING &&
    currentOrg?.type === AccountType.INDIVIDUAL &&
    !!appConfig?.is_individual_manual_verification_required
  )
    return <ThankyouPage />;

  if ([OrgStatus.PENDING, OrgStatus.REJECTED].includes(currentOrg?.status))
    return (
      <OrgPreApprovedStage
        currentOrgStatus={currentOrg?.status}
        documentStatus={currentOrg?.document_status}
      />
    );

  if (!currentOrg?.id) return null;

  return <div>{children}</div>;
};

export default ApprovalGuard;
