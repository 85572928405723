import { DateRange, Range, RangeKeyDict } from 'react-date-range';

import { CalendarProps } from '@types';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import colors from '../../../themes/colors';

const Calendar: React.FC<CalendarProps> = ({
  startDate,
  endDate,
  handleChange,
  maxDate,
  minDate
}) => {
  const currentDate = new Date();
  const selectionRange: Range = {
    startDate: startDate || currentDate,
    endDate: endDate || currentDate,
    key: 'selection'
  };

  const handleSelect = (ranges: RangeKeyDict) => {
    if (handleChange) {
      const { selection } = ranges;
      handleChange({ selection });
    }
  };

  return (
    <div>
      <DateRange
        ranges={[selectionRange]}
        onChange={handleSelect}
        showDateDisplay={false}
        className="!bg-bgBase !text-content"
        showMonthAndYearPickers={false}
        rangeColors={[`${colors.primary}`]}
        maxDate={maxDate}
        minDate={minDate}
      />
    </div>
  );
};

export default Calendar;
