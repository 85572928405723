import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';

import { TEAM_MANAGEMENT } from '@constants/routes';

const InviteMember = lazy(
  () => import('@features/team-management/pages/invite-member/InviteMember')
);

const TeamManagement = lazy(
  () => import('@features/team-management/pages/team-management/TeamManagement')
);

export const TeamManagementRoutes: RouteObject[] = [
  {
    path: TEAM_MANAGEMENT.TEAM_MANAGEMENT,
    children: [
      {
        path: TEAM_MANAGEMENT.INVITE_MEMBER,
        element: <InviteMember />
      },
      {
        path: TEAM_MANAGEMENT.TEAM_MANAGEMENT,
        element: <TeamManagement />
      },
      { path: TEAM_MANAGEMENT.EDIT_ROLE, element: <InviteMember /> }
    ]
  }
];

export default TeamManagementRoutes;
