import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { sendMixpanelEvent, Event } from '@core/analytics';

import { SidebarItemProps } from './types';

const SidebarItem = (props: SidebarItemProps) => {
  const { isSidebarOpen, isCurrentRoute, item, path } = props;

  const { id, Icon, label, route } = item;

  const itemBgColor = isCurrentRoute ? 'bg-bgGrey' : '';
  const itemColor = isCurrentRoute ? 'text-primary' : 'text-grey';

  const sideBarClick = () => {
    sendMixpanelEvent(Event.SIDEBAR_TAB_CLICKED, {
      section: label
    });
  };

  return (
    <Link
      key={id}
      to={path || route}
      className={`flex items-center gap-3 rounded-lg px-4 py-3 ${itemBgColor}`}
      onClick={sideBarClick}
    >
      <div>
        <Icon className={clsx('size-6', itemColor)} />
      </div>
      {isSidebarOpen && (
        <span
          className={`truncate text-sm font-medium ${itemColor}
      `}
        >
          {label}
        </span>
      )}
    </Link>
  );
};

export default SidebarItem;
