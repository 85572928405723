import { useRef, useState } from 'react';

import { FileForm } from '@types';
import useNotify from '@hooks/notify';
import { acceptedAttachmentFormats } from '@constants/file';

import { MultiUploadProps, PreviewType } from './types';
import Icon from '../icon/Icon';
import theme from '../../../themes/colors';

const previewBoxStyles =
  'size-23.5 rounded-10 object-cover border border-grey10';

const MultiUpload = (props: MultiUploadProps) => {
  const {
    filePreviewList,
    showDeleteIcon,
    onClearFiles,
    onFileChange,
    imgClassName,
    maxImageSupported = 5,
    maxVideoSupported = 3,
    loading
  } = props;

  const [hoveredItemIndex, setHoveredItemIndex] = useState<
    number | undefined
  >();

  const videoPlayerRef = useRef(null);

  const { createErrorAlert } = useNotify();

  const isFileValid = (selectedFiles: File[]): boolean => {
    const totalFiles = [...selectedFiles, ...(filePreviewList || [])];

    if (totalFiles.length > maxImageSupported + maxVideoSupported) {
      createErrorAlert('You can upload a maximum of 8 files in total');
      return false;
    }

    const imageFiles = [];
    const videoFiles = [];

    totalFiles.forEach((file) => {
      if (file.type?.includes('image')) {
        imageFiles.push(file);
      } else if (file.type?.includes('video')) {
        videoFiles.push(file);
      }
    });

    let validationMessage = '';

    if (imageFiles.length > maxImageSupported) {
      validationMessage = 'You can upload a maximum of 5 images';
    } else if (videoFiles.length > maxVideoSupported) {
      validationMessage = 'You can upload a maximum of 3 videos';
    }
    if (validationMessage !== '') {
      createErrorAlert(validationMessage);
      return false;
    }
    return true;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const selectedFiles = e?.target?.files;
    if (selectedFiles) {
      const fileListAsArray = Array.from(selectedFiles);

      if (isFileValid(fileListAsArray)) {
        const newList: FileForm[] = [];
        fileListAsArray.forEach((file: File) => {
          if (file.type) {
            const preview = URL.createObjectURL(file);
            newList.push({ file, preview });
          }
        });

        onFileChange(newList, index);
      }
    }
  };

  const renderImageSelection = () => {
    const isUploadDisabled =
      filePreviewList &&
      filePreviewList?.length >= maxImageSupported + maxVideoSupported;
    return (
      <div className={loading ? 'cursor-not-allowed' : 'cursor-pointer'}>
        <div
          className={`flex ${
            isUploadDisabled || loading
              ? 'pointer-events-none opacity-75'
              : 'pointer-events-auto'
          }`}
        >
          <input
            type="file"
            id="file-input"
            multiple
            className="hidden"
            accept={acceptedAttachmentFormats}
            onChange={onChange}
          />
          <label
            htmlFor="file-input"
            className="flex cursor-pointer self-center"
          >
            <div className="mb-3 flex items-center">
              <Icon
                name="plus"
                size="medium"
                stroke="grey"
                className="mr-3 size-6 cursor-pointer rounded-2xl bg-grey10 p-1"
              />
              <span
                className={`${
                  isUploadDisabled
                    ? 'text-grey'
                    : 'text-primary underline underline-offset-4'
                }`}
              >
                Attach image/videos
              </span>
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderVideoPreview = (fileItem: PreviewType, index: number) => (
    <div className="video-wrapper">
      <video
        src={fileItem.preview}
        ref={videoPlayerRef}
        className={`
        ${previewBoxStyles} ${imgClassName} ${
          hoveredItemIndex === index ? 'opacity-50' : 'opacity-100'
        }`}
        onLoadedData={() => {
          if (videoPlayerRef.current) {
            (videoPlayerRef.current as HTMLVideoElement).currentTime = 0; // this will set first frame of video as thumbnail
          }
        }}
      >
        <track kind="captions" />
      </video>
      <Icon
        name="play-button"
        size="medium"
        className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  );

  const onClear = (index: number) => {
    if (onClearFiles) {
      onClearFiles(index);
    }
  };

  const renderFileItem = (fileItem: PreviewType, index: number) => {
    const fileInputId = `image-input-${index}`;

    return (
      <div className={loading ? 'cursor-not-allowed' : 'cursor-pointer'}>
        <div
          key={fileItem.preview}
          className={`relative flex w-fit ${
            loading ? 'pointer-events-none opacity-75' : ' pointer-events-auto '
          }`}
          onMouseEnter={() => setHoveredItemIndex(index)}
          onMouseLeave={() => setHoveredItemIndex(undefined)}
        >
          <label
            htmlFor={fileInputId}
            className="relative cursor-pointer self-center"
          >
            {fileItem.type?.includes('video') ? (
              renderVideoPreview(fileItem, index)
            ) : (
              <img
                src={fileItem.preview}
                className={`
                ${previewBoxStyles} ${imgClassName} ${
                  hoveredItemIndex === index ? 'opacity-50' : 'opacity-100'
                }`}
                alt="preview"
              />
            )}
          </label>
          {showDeleteIcon && (
            <div className="absolute right-2 top-2 z-10 flex size-4.5 cursor-pointer items-center justify-center rounded-full  bg-grey80 p-1.5">
              <Icon
                size="small"
                name="close-white"
                fill="white"
                onClick={() => {
                  onClear(index);
                }}
              />
            </div>
          )}
          {hoveredItemIndex === index && (
            <label
              htmlFor={fileInputId}
              className="pointer-events-none absolute bottom-1/2 left-1/2 z-50 flex -translate-x-1/2 translate-y-1/2 cursor-pointer items-center justify-center"
            >
              <Icon name="image-upload" size="24" />
            </label>
          )}
          <input
            type="file"
            id={fileInputId}
            className="hidden"
            accept={acceptedAttachmentFormats}
            onChange={(e) => {
              onChange(e, index);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex flex-wrap gap-4 py-6">
      <div className="w-full">
        {renderImageSelection()}
        <div className="flex items-center">
          <Icon name="alert-circle" stroke={theme.subText} />
          <span className="ml-2 text-xs text-grey">
            Maximum of 5 images and 3 videos can be attached
          </span>
        </div>
      </div>
      {filePreviewList &&
        filePreviewList.map((fileItem: PreviewType, index: number) =>
          renderFileItem(fileItem, index)
        )}
    </div>
  );
};

export default MultiUpload;
