import Tooltip from '../tooltip/Tooltip';
import Icon from '../icon/Icon';
import theme from '../../../themes/colors';

interface LabelProps {
  label: string;
  optionalText?: string;
  tooltipText?: string;
  className?: string;
  tooltipId?: string;
  tooltipStyle?: string;
}

const Label = (props: LabelProps) => {
  const {
    label,
    optionalText,
    tooltipText,
    className,
    tooltipId,
    tooltipStyle
  } = props;

  return (
    <div
      className={`mb-4 flex items-center gap-2 text-sm font-semibold text-grey ${className}`}
    >
      <div>
        {label}
        <span className="ml-1 text-grey60">{optionalText}</span>
      </div>
      {tooltipText && (
        <>
          <Icon
            name="alert-circle"
            stroke={theme.subText}
            data-tooltip-id={`${tooltipId}-label-tooltip`}
          />
          <Tooltip
            id={`${tooltipId}-label-tooltip`}
            type="text"
            tooltipText={tooltipText}
            className={tooltipStyle}
          />
        </>
      )}
    </div>
  );
};

export default Label;
