import { useState } from 'react';

import Icon from '@components/icon/Icon';
import { MonthArray } from '@constants/groups';
import { PickerProps } from '@types';

const MonthPicker = ({ handleDateRangeSelect, minDate }: PickerProps) => {
  const currentDate = new Date();
  const [selectedYear, setSelectedYear] = useState<number>(
    currentDate.getFullYear()
  );

  const [selectedDate, setSelectedDate] = useState<{
    year: number;
    month: {
      id: number;
      value: string;
      label: string;
    };
  }>();

  const checkForFutureMonth = (item: any) => {
    if (
      item.id > currentDate.getMonth() &&
      selectedYear === currentDate.getFullYear()
    )
      return true;
    return false;
  };

  const checkForPastMonth = (item: any) => {
    if (selectedYear === minDate?.getFullYear())
      if (item.id < minDate.getMonth()) return true;
    return false;
  };

  const handleMonthClick = (item: any) => {
    if (!checkForFutureMonth(item) && !checkForPastMonth(item)) {
      const startOfMonth = new Date(selectedYear, item.id, 1);
      const endOfMonth = new Date(selectedYear, item.id + 1, 0);
      handleDateRangeSelect({
        startDate: startOfMonth,
        endDate: endOfMonth
      });
      setSelectedDate({ year: selectedYear, month: item });
    }
  };

  const handleReduceYear = () => {
    if (minDate) {
      if (selectedYear > minDate.getFullYear()) {
        setSelectedYear((prev) => prev - 1);
      }
    } else setSelectedYear((prev) => prev - 1);
  };

  const handleAddYear = () => {
    if (selectedYear < currentDate.getFullYear()) {
      setSelectedYear((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="text-sm font-normal text-grey">Year</div>
      <div className="my-4 flex w-full items-center justify-between rounded-lg bg-grey10 p-6 text-sm text-content">
        <Icon
          name="left"
          role="presentation"
          className="cursor-pointer"
          onClick={handleReduceYear}
        />
        <div>{selectedYear}</div>
        <Icon
          name="right"
          role="presentation"
          className="cursor-pointer"
          onClick={handleAddYear}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {MonthArray.map((item) => (
          <div
            key={item.id}
            className={`flex h-8 w-20 items-center justify-center rounded-lg text-grey
            ${
              checkForFutureMonth(item) || checkForPastMonth(item)
                ? 'cursor-not-allowed bg-grey10 text-grey40'
                : 'cursor-pointer border border-grey2 bg-white text-grey'
            } ${
              selectedDate?.month.id === item.id &&
              selectedDate?.year === selectedYear
                ? 'border !border-primary !text-primary'
                : ''
            }`}
            role="presentation"
            onClick={() => handleMonthClick(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthPicker;
