import { useState } from 'react';

import { dayOptions } from '@components/payment-collection/constants';
import { DayOptionsType } from '@types';

interface DayPickerProps {
  onChange: (selectedDays: DayOptionsType[]) => void;
  markedDays?: DayOptionsType[];
}

const DayPicker: React.FC<DayPickerProps> = ({ onChange, markedDays }) => {
  const [selectedDays, setSelectedDays] = useState<DayOptionsType[]>(
    markedDays || []
  );

  const handleDayClick = (item: DayOptionsType) => {
    const prevSelectedDays = [...selectedDays];
    const newSelectedDays = prevSelectedDays.some((day) => day.id === item.id)
      ? prevSelectedDays.filter((day) => day.id !== item.id)
      : [...prevSelectedDays, item];
    setSelectedDays(newSelectedDays);
    onChange(newSelectedDays);
  };

  return (
    <div>
      <div className="flex gap-2 pb-4">
        {dayOptions.map((item) => (
          <div
            key={item.id}
            className={`flex size-12.5 cursor-pointer items-center justify-center rounded-lg border ${
              selectedDays.some((day) => day.id === item.id)
                ? 'border-primary bg-white text-primary'
                : 'border-grey40 bg-grey10 text-grey'
            }`}
            role="presentation"
            onClick={() => handleDayClick(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayPicker;
