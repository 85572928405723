export const allowedFileTypes = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'image/x-eps'
];

export const acceptedInputTypes =
  'image/jpeg,image/jpg,image/gif,image/png,application/pdf,image/x-eps';

export const previewAvailableFiles = [
  'jpeg',
  'jpg',
  'png',
  'PNG',
  'JPG',
  'JPEG'
];
