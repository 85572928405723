import { ChipProps } from './types';
import Icon from '../icon/Icon';

const Chip = ({
  label,
  className,
  onRemove,
  handleChipClick,
  isCloseIconRequired = true,
  labelStyle = 'max-w-20'
}: ChipProps) => (
  <div
    role="presentation"
    onClick={(e) => handleChipClick?.(label, e)}
    className={`${
      className || ''
    } flex h-7 w-fit items-center rounded-2xl px-2 py-0.5 text-xs font-medium text-content `}
  >
    <div className={`truncate break-words ${labelStyle || ''}`}>{label}</div>
    {isCloseIconRequired && onRemove && (
      <div
        role="presentation"
        onClick={onRemove}
        className="ml-2 cursor-pointer"
      >
        <Icon name="close" />
      </div>
    )}
  </div>
);

export default Chip;
