import Icon from '@components/icon/Icon';
import Tooltip from '@components/tooltip/Tooltip';

import theme from '../../../themes/colors';

interface InfoIconTooltipProps {
  id: string;
  tooltipText: string;
  classname?: string;
}

const InfoIconTooltip = ({
  id,
  tooltipText,
  classname
}: InfoIconTooltipProps) => (
  <>
    <Icon
      name="alert-circle"
      className="text-grey40"
      stroke={theme.subText}
      data-tooltip-id={`${id}-info-icon`}
    />
    <Tooltip
      id={`${id}-info-icon`}
      type="text"
      tooltipText={tooltipText}
      className={`leading-5 ${classname || ''}`}
    />
  </>
);

export default InfoIconTooltip;
