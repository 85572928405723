import { useEffect, useState } from 'react';

import { Button, Dropdown } from '@components';
import {
  PaymentCollectionOptions,
  PaymentCollectionType
} from '@features/group-management/constants';
import { PaymentCollectionComponentType, PaymentCollectionProps } from '@types';

import WeekCard from './components/WeekCard';
import MonthCard from './components/MonthCard';
import TermCard from './components/TermCard';

const PaymentCollection = ({
  type = PaymentCollectionComponentType.DEFAULT,
  cancelButtonLabel,
  confirmButtonLabel,
  cancelButtonStyle = '',
  confirmButtonStyle = '',
  handleConfirm,
  handleCancel,
  className,
  selectedtype,
  selectedFrequency,
  selectedDate,
  nextDueDate
}: PaymentCollectionProps) => {
  const [selectedFilter, setSelectedFilter] = useState<PaymentCollectionType>(
    selectedtype || PaymentCollectionType.MONTHLY
  );

  const [activationDate, setActivationDate] = useState<Date>();
  const [intervalFrequency, setIntervalFrequency] = useState<string>('');
  const [showConsent, setShowConsent] = useState<boolean>(false);

  const handleFilterSelection = (filterValue: string) => {
    setSelectedFilter(filterValue as PaymentCollectionType);
  };

  const handleActivationDate = (date: Date, consentToBeShown = false) => {
    setActivationDate(date);
    setShowConsent(consentToBeShown);
  };

  const handleIntervalFrequency = (frequency: string) => {
    setIntervalFrequency(frequency);
  };

  const renderCard = () => {
    switch (selectedFilter) {
      case PaymentCollectionType.MONTHLY:
        return (
          <MonthCard
            handleActivationDate={handleActivationDate}
            handleIntervalFrequency={handleIntervalFrequency}
            selectedDate={selectedDate}
            type={selectedtype}
          />
        );
      case PaymentCollectionType.WEEKLY:
        return (
          <WeekCard
            handleActivationDate={handleActivationDate}
            handleIntervalFrequency={handleIntervalFrequency}
            selectedDate={selectedDate}
            type={selectedtype}
          />
        );
      case PaymentCollectionType.BY_TERM:
        return (
          <TermCard
            handleActivationDate={handleActivationDate}
            handleIntervalFrequency={handleIntervalFrequency}
            selectedDate={selectedDate}
            selectedFrequency={selectedFrequency}
            type={selectedtype}
            isTermDisabled={type !== PaymentCollectionComponentType.DEFAULT}
            nextDueDate={nextDueDate}
          />
        );
      default:
        return null;
    }
  };

  const getPaymentCollectionText = () => {
    let displayText: string = '';
    if (type === PaymentCollectionComponentType.MONTHLY)
      displayText =
        'Collection frequency is currently set to monthly and cannot be changed';
    else if (type === PaymentCollectionComponentType.WEEKLY)
      displayText =
        'Collection frequency is currently set to weekly and cannot be changed';
    else if (type === PaymentCollectionComponentType.BY_TERM)
      displayText = `Collection frequency is currently set for a term of ${intervalFrequency} months, and cannot be changed`;
    return displayText;
  };

  useEffect(() => {
    if (type !== PaymentCollectionComponentType.DEFAULT)
      if (type === PaymentCollectionComponentType.MONTHLY)
        setSelectedFilter(PaymentCollectionType.MONTHLY);
      else if (type === PaymentCollectionComponentType.WEEKLY)
        setSelectedFilter(PaymentCollectionType.WEEKLY);
      else if (type === PaymentCollectionComponentType.BY_TERM)
        setSelectedFilter(PaymentCollectionType.BY_TERM);
  }, [type]);

  useEffect(() => {
    if (selectedtype) setSelectedFilter(selectedtype);
  }, [selectedtype]);

  const getActivationDate = () => activationDate || selectedDate || new Date();

  return (
    <div
      className={`flex w-83.5 flex-col justify-start rounded-lg p-6 text-sm font-normal ${
        className || ''
      }`}
    >
      <div className="mb-6 w-full text-center text-base">
        Payment Collection
      </div>
      {type === PaymentCollectionComponentType.DEFAULT ? (
        <div className="justify start mb-4 flex items-center gap-4">
          <div className="text-grey">Frequency : </div>
          <Dropdown
            currentFilter={selectedFilter}
            filterOptions={PaymentCollectionOptions}
            onSelectFilter={handleFilterSelection}
            className="!text-normal h-8.25 min-w-25 rounded-lg !border-none !bg-grey10"
            optionsStyle="!w-45.5"
          />
        </div>
      ) : (
        <div
          className={`${
            type &&
            'mb-4 flex items-center justify-center rounded-lg bg-grey10 px-4 py-2 text-grey'
          }`}
        >
          {getPaymentCollectionText()}
        </div>
      )}
      {renderCard()}
      <div className="flex justify-between">
        <Button
          handleClick={handleCancel}
          variant="transparent"
          size="none"
          className={`ml-4 text-base font-medium ${
            cancelButtonStyle && cancelButtonStyle
          }`}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          handleClick={() =>
            handleConfirm({
              date: getActivationDate(),
              interval: intervalFrequency,
              type: selectedFilter,
              isFutureDate: showConsent
            })
          }
          variant="transparent"
          size="none"
          className={`mr-4 text-base font-medium text-primary ${
            confirmButtonStyle && confirmButtonStyle
          }`}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default PaymentCollection;
