import { useEffect, useId, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AddMemberModalType,
  AddMemberProps,
  PaymentCollectionArg,
  PaymentCollectionComponentType,
  ValidationError
} from '@types';
import {
  getFormattedPhoneNumber,
  isMinLengthSatisfied,
  sanitizeNumberInput,
  getNameErrorMessage,
  getAmountErrorMessage
} from '@utils/generic';
import {
  isInvalidMemberName,
  isInvalidPhoneNumber,
  isInvalidAmount
} from '@utils/validations';
import { PaymentCollectionType } from '@features/group-management/constants';
import { invalidCharInNameRegex, phoneNumberRegex } from '@constants/regex';
import {
  GROUP_MEMBER_MAX_AMOUNT,
  GROUP_MEMBER_MIN_AMOUNT,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  duplicatePhoneNumberErrorMessage,
  phoneNumberErrorMessage
} from '@constants/generic';
import { formatDueDatePlaceholder } from '@features/group-management/helper';

import AmountInput from '../amount-input/AmountInput';
import AmountInputTooltip from '../amount-input-tooltip/AmountInputTooltip';
import Button from '../button/Button';
import Input from '../input/Input';
import PaymentCollection from '../payment-collection/PaymentCollection';
import Label from '../label/Label';
import CountryCode from '../country-code/CountryCode';
import Modal from '../../layouts/modal/Modal';

const AddMemberModal = ({
  type = AddMemberModalType.ADD,
  isOpen,
  handleCancel,
  addMember,
  paymentCollectionType,
  selectedmember,
  groupAmount,
  groupDueDate,
  groupFrequency
}: AddMemberProps) => {
  const id = useId();

  const [name, setName] = useState<string>('');
  const [primaryNumber, setPrimaryNumber] = useState<string>('');
  const [altNumber, setAltNumber] = useState<string>('');
  const [amount, setAmount] = useState<string>(groupAmount || '');
  const [dueDate, setDueDate] = useState<string>(groupDueDate || '');

  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<string>('');
  const [error, setError] = useState<ValidationError>({});

  const { orgConfig } = useSelector((state: any) => state.user);

  const groupMemberMaxAmount =
    orgConfig?.group_member_amount_max || GROUP_MEMBER_MAX_AMOUNT;
  const groupMemberMinAmount =
    orgConfig?.group_member_amount_min || GROUP_MEMBER_MIN_AMOUNT;
  const memberNameMaxLength =
    orgConfig?.member_name_length_max || NAME_MAX_LENGTH;
  const memberNameMinLength =
    orgConfig?.member_name_length_min || NAME_MIN_LENGTH;

  const onMemberNameChange = (value: string) => {
    setError({ ...error, name: '' });
    setName(value);
  };

  const onMemberNameBlur = (value: string) => {
    if (!isMinLengthSatisfied(value))
      setError({
        ...error,
        name: getNameErrorMessage(memberNameMinLength)
      });
  };

  const onPrimaryNumberChange = (value: string) => {
    if (primaryNumber === altNumber) {
      setError({ ...error, altNum: '', primaryNum: '' });
    } else {
      setError({ ...error, primaryNum: '' });
    }
    if (value.length <= 10) {
      setPrimaryNumber(value);
    }
  };

  const onPrimaryNumberBlur = (value: string) => {
    let errorMessage = '';
    if (!isMinLengthSatisfied(value, 10))
      errorMessage = phoneNumberErrorMessage;
    else if (value && primaryNumber === altNumber)
      errorMessage = duplicatePhoneNumberErrorMessage;
    if (errorMessage !== '') setError({ ...error, primaryNum: errorMessage });
  };

  const onAltNumberChange = (value: string) => {
    if (altNumber === primaryNumber) {
      setError({ ...error, primaryNum: '', altNum: '' });
    } else {
      setError({ ...error, altNum: '' });
    }

    if (value.length <= 10) {
      setAltNumber(value);
    }
  };

  const onAltNumberBlur = (value: string) => {
    let errorMessage = '';
    if (!isMinLengthSatisfied(value, 10))
      errorMessage = phoneNumberErrorMessage;
    else if (value && primaryNumber === altNumber)
      errorMessage = duplicatePhoneNumberErrorMessage;
    if (errorMessage !== '') setError({ ...error, altNum: errorMessage });
  };

  const onAmountChange = (value: string) => {
    setError({ ...error, amount: '' });
    const sanitizedAmount = sanitizeNumberInput(value);
    setAmount(sanitizedAmount);
  };

  const onAmountBlur = (value: string) => {
    if (value.length && Number(value) < Number(groupMemberMinAmount)) {
      setError({
        ...error,
        amount: getAmountErrorMessage(groupMemberMinAmount)
      });
    }
  };

  const saveCurrentMember = () => {
    addMember({
      primaryNumber,
      name,
      altNumber: altNumber || '',
      amount: amount || groupAmount || '',
      dueDate: dueDate || groupDueDate || '',
      id:
        type === AddMemberModalType.EDIT
          ? memberId
          : `member_${id}_${primaryNumber}`
    });
  };

  const onSave = () => {
    saveCurrentMember();
    handleCancel();
  };

  const onSaveAndAddAnother = () => {
    saveCurrentMember();
    setName('');
    setPrimaryNumber('');
    setAltNumber('');
    setAmount(groupAmount || '');
    setDueDate(groupDueDate || '');
    setIsCalendarVisible(false);
  };

  const onDueDateChange = (data: PaymentCollectionArg) => {
    setDueDate(String(data.date));
    setIsCalendarVisible(false);
  };

  const isSaveDisabled =
    (amount &&
      isInvalidAmount(amount, groupMemberMaxAmount, groupMemberMinAmount)) ||
    isInvalidMemberName(name, memberNameMaxLength, memberNameMinLength) ||
    isInvalidPhoneNumber(primaryNumber) ||
    !!(
      altNumber &&
      (isInvalidPhoneNumber(altNumber) || primaryNumber === altNumber)
    );

  const hasChanges =
    selectedmember?.name !== name ||
    getFormattedPhoneNumber(selectedmember?.primaryNumber || '') !==
      primaryNumber ||
    selectedmember.altNumber !== altNumber ||
    selectedmember.amount !== amount ||
    selectedmember.dueDate !== dueDate;

  const getSelectedDate = () => {
    if (selectedmember?.dueDate) return new Date(selectedmember?.dueDate);
    if (dueDate) return new Date(dueDate);
    return undefined;
  };

  useEffect(() => {
    if (type === AddMemberModalType.EDIT) {
      setName(selectedmember?.name || '');
      setPrimaryNumber(
        getFormattedPhoneNumber(selectedmember?.primaryNumber || '')
      );
      setAltNumber(getFormattedPhoneNumber(selectedmember?.altNumber || ''));
      setAmount(selectedmember?.amount || groupAmount || '');
      setDueDate(selectedmember?.dueDate || groupDueDate || '');
      setMemberId(selectedmember?.id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (isCalendarVisible) {
      const element = document.getElementById('due-date-calendar');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, [isCalendarVisible]);

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} isCloseIconRequired>
      <div className="relative flex h-165 min-w-488 flex-col justify-start gap-4 rounded-lg bg-theme p-6">
        <div className="absolute left-0 top-0 h-auto py-6 pl-6 text-xl font-medium">
          {type === AddMemberModalType.EDIT
            ? 'Edit Member Details'
            : 'Add Member Details'}
        </div>
        <div className="absolute bottom-17 left-0 top-12 my-6 flex w-full flex-col gap-4 overflow-y-auto px-6">
          <Label label="Name" className="!mb-0" />
          <Input
            value={name}
            handleInputChange={onMemberNameChange}
            inputRegex={invalidCharInNameRegex}
            type="text"
            placeholder="Name"
            maxLength={memberNameMaxLength}
            minLength={memberNameMinLength}
            error={!!error.name}
            errorMessage={error.name}
            handleInputBlur={onMemberNameBlur}
          />
          <Label
            label="Primary Number"
            className={`!mb-0 ${error.name ? 'mt-2' : ''}`}
          />
          <div className="flex gap-3">
            <CountryCode />
            <Input
              className="w-full"
              value={primaryNumber}
              handleInputChange={onPrimaryNumberChange}
              inputRegex={phoneNumberRegex}
              placeholder="Enter phone number"
              type="phone"
              error={!!error.primaryNum}
              errorMessage={error.primaryNum}
              handleInputBlur={onPrimaryNumberBlur}
            />
          </div>
          <Label
            label="Alternate Number"
            className={`!mb-0 ${error.primaryNum ? 'mt-2' : ''}`}
          />
          <div className="flex gap-3">
            <CountryCode />
            <Input
              className="w-full"
              value={altNumber}
              handleInputChange={onAltNumberChange}
              inputRegex={phoneNumberRegex}
              placeholder="Enter phone number"
              type="phone"
              error={!!error.altNum}
              errorMessage={error.altNum}
              handleInputBlur={onAltNumberBlur}
            />
          </div>
          <div className={`flex  ${error.altNum ? 'mt-2' : ''}`}>
            <Label label="Amount" className="!mb-0 mr-2" />
            <AmountInputTooltip
              maxAmount={groupMemberMaxAmount}
              minAmount={groupMemberMinAmount}
              tooltipId="add-member-modal"
            />
          </div>
          <AmountInput
            value={amount}
            placeholder="Enter Amount"
            onAmountChange={onAmountChange}
            maxAmount={groupMemberMaxAmount}
            handleInputBlur={onAmountBlur}
            error={!!error.amount}
            errorMessage={error.amount}
          />
          <Label
            label="Due Date"
            tooltipText="If not given, the due date of group will be taken into account"
            className={`!mb-0 ${error.amount ? 'mt-2' : ''}`}
            tooltipId="dueDate"
          />
          <Input
            leftIconName="calendar"
            value={
              dueDate
                ? formatDueDatePlaceholder(
                    paymentCollectionType || PaymentCollectionType.MONTHLY,
                    groupFrequency || 1,
                    new Date(dueDate)
                  )
                : ''
            }
            onClick={() => setIsCalendarVisible(true)}
            placeholder="Select Date"
            readOnly
          />
          {isCalendarVisible && (
            <div
              className="mx-10 my-6 rounded-lg border"
              id="due-date-calendar"
            >
              <PaymentCollection
                type={paymentCollectionType as PaymentCollectionComponentType}
                isOpen={isCalendarVisible}
                confirmButtonLabel="Confirm"
                confirmButtonStyle="text-primary"
                handleConfirm={onDueDateChange}
                cancelButtonLabel="Cancel"
                handleCancel={() => {
                  setIsCalendarVisible(false);
                }}
                className="max-w-327"
                selectedDate={getSelectedDate()}
                selectedtype={
                  (paymentCollectionType as PaymentCollectionType) ||
                  PaymentCollectionType.MONTHLY
                }
                selectedFrequency={groupFrequency}
              />
            </div>
          )}
        </div>
        {/* TODO: button disabled needs to be handled */}
        <div className="absolute bottom-0 left-0 flex h-auto w-full gap-6 p-6">
          {type === AddMemberModalType.ADD && (
            <Button
              label="Save and add another"
              variant="secondary"
              handleClick={onSaveAndAddAnother}
              disabled={isSaveDisabled}
              className="w-full"
            />
          )}
          <Button
            label="Save"
            handleClick={onSave}
            disabled={isSaveDisabled || !hasChanges}
            className={type === AddMemberModalType.EDIT ? 'w-full' : 'w-full'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
