/* eslint-disable import/prefer-default-export */
export const MonthlyPaymentsXAxisValues = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const MonthArray = [
  { id: 0, value: 'January', label: 'Jan' },
  { id: 1, value: 'February', label: 'Feb' },
  { id: 2, value: 'March', label: 'Mar' },
  { id: 3, value: 'April', label: 'Apr' },
  { id: 4, value: 'May', label: 'May' },
  { id: 5, value: 'June', label: 'Jun' },
  { id: 6, value: 'July', label: 'Jul' },
  { id: 7, value: 'August', label: 'Aug' },
  { id: 8, value: 'September', label: 'Sept' },
  { id: 9, value: 'October', label: 'Oct' },
  { id: 10, value: 'November', label: 'Nov' },
  { id: 11, value: 'December', label: 'Dec' }
];

export enum DatePickerType {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  CUSTOM = 'custom',
  YESTERDAY = 'yesterday'
}

export const DatePickerOptions = [
  { id: 1, label: 'Custom', value: DatePickerType.CUSTOM },
  { id: 2, label: 'Yesterday', value: DatePickerType.YESTERDAY },
  { id: 3, label: 'This Week', value: DatePickerType.WEEKLY },
  { id: 3, label: 'Month', value: DatePickerType.MONTHLY }
];

export const AttendanceDatePickerOptions = [
  { id: 1, label: 'Month', value: DatePickerType.MONTHLY },
  { id: 2, label: 'Custom', value: DatePickerType.CUSTOM }
];
