import { useEffect, useRef, useState } from 'react';

import { ReactComponent as DeleteIcon } from '@assets/icons/trash.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/filled-double-layer-check.svg';
import { FileUploadProps, FileUploadType } from '@types';
import useDragAndDrop from '@hooks/useDragAndDrop';
import {
  allowedDocumentFileSize,
  allowedImageSizeLabel
} from '@constants/file';
import { bytesToMB } from '@utils/file';
import Tooltip from '@components/tooltip/Tooltip';
import { sendMixpanelEvent, Event } from '@core/analytics';

import { acceptedInputTypes, allowedFileTypes } from './constants';
import Icon from '../icon/Icon';

const FileUpload = ({
  label,
  handleAdd,
  handleRemove,
  type = FileUploadType.DEFAULT,
  reason,
  uploadedFile
}: FileUploadProps) => {
  const {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError
  } = useDragAndDrop();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inputId = `${label}_input_id`;

  const [file, setFile] = useState<File | undefined | null>(uploadedFile);

  const isValidFile = (selectedFile: File) => {
    if (selectedFile.size > allowedDocumentFileSize) {
      setFileDropError(
        `Upload failed. File size more than ${allowedImageSizeLabel}`
      );
      sendMixpanelEvent(Event.FILE_UPLOAD_ERROR, {
        validation_error: `File size exceeded ${allowedImageSizeLabel}`,
        document_type: label
      });
      return false;
    }
    if (!allowedFileTypes.includes(selectedFile.type)) {
      setFileDropError('Upload failed. Unsupported file type');
      sendMixpanelEvent(Event.FILE_UPLOAD_ERROR, {
        validation_error: 'Unsupported file format',
        document_type: label
      });
      return false;
    }
    return true;
  };

  const handleFileProcessing = async (selectedFile: File) => {
    setFileDropError('');
    setFile(selectedFile);
    handleAdd(selectedFile);
  };

  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e?.target?.files?.[0];
    if (selectedFile && isValidFile(selectedFile)) {
      handleFileProcessing(selectedFile);
    }
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);

    const selectedFile = e?.dataTransfer?.files[0];
    if (isValidFile(selectedFile)) {
      handleFileProcessing(selectedFile);
    }
  };

  const deleteFile = () => {
    setFile(undefined);
    handleRemove();
  };

  useEffect(() => {
    setFile(uploadedFile);
  }, [uploadedFile]);

  const renderWarning = () => (
    <>
      <Icon
        name="filled-alert"
        size="medium"
        data-tooltip-id={`${label}-rejected-info`}
      />
      <Tooltip id={`${label}-rejected-info`} place="bottom">
        {reason && (
          <div className="grid gap-6">
            <div className="text-sm text-subText">
              <span className="text-white">Issue:</span> {reason}
            </div>
          </div>
        )}
      </Tooltip>
    </>
  );

  const renderDragAndDropWithFileSelection = () => (
    <>
      <input
        type="file"
        id={inputId}
        className="hidden"
        onChange={onFileSelect}
        accept={acceptedInputTypes}
        ref={fileInputRef}
      />
      <div
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={`${dragOver ? 'bg-grey70' : ''}`}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex justify-start gap-4">
            {type !== FileUploadType.RE_UPLOAD && (
              <div>
                <Icon
                  name={fileDropError ? 'error' : 'upload'}
                  size="none"
                  className="size-8"
                  key={fileDropError}
                />
              </div>
            )}
            <div className="w-full">
              <div className="flex items-center justify-start gap-3">
                <div className="text-base font-medium text-grey">{label}</div>
                {type === FileUploadType.RE_UPLOAD && renderWarning()}
              </div>
              {fileDropError ? (
                <div className="text-xs text-error">{fileDropError}</div>
              ) : (
                <div className="text-xs font-normal text-grey">
                  PDF, JPG, JPEG, PNG format
                </div>
              )}
            </div>
          </div>
          <label
            htmlFor={inputId}
            className="cursor-pointer rounded-lg border border-primary bg-primaryLite1 px-4 py-1.5 text-primary"
          >
            Upload
          </label>
        </div>
      </div>
    </>
  );

  const fileCard = () => (
    <div className="flex w-full items-center justify-between">
      <div className="flex justify-start gap-4">
        <div className="flex size-8 items-center justify-center">
          <CheckIcon />
        </div>
        <div className="w-full">
          <div className="w-60 truncate text-base font-medium">{label}</div>
          <div className="flex w-full gap-1 text-xs font-normal text-grey">
            <div className="w-40 truncate">{file?.name}</div>
            <div>|</div>
            <div>{bytesToMB(file?.size)}</div>
          </div>
        </div>
      </div>
      <div className="cursor-pointer" role="presentation" onClick={deleteFile}>
        <DeleteIcon />
      </div>
    </div>
  );

  return !file ? renderDragAndDropWithFileSelection() : fileCard();
};
export default FileUpload;
