import clsx from 'clsx';

import IndiaFlagIcon from '@assets/images/flag-india.svg';

import { CountryCodeProps } from './types';

const CountryCode = (props: CountryCodeProps) => {
  const { className } = props;

  return (
    <div
      className={clsx(
        'flex h-12.5 items-center justify-center gap-2 rounded-xl border bg-grey10 p-4',
        className
      )}
    >
      <img src={IndiaFlagIcon} className="h-3 w-4.5" alt="india-flag" />
      <div className="text-content">+91</div>
    </div>
  );
};

export default CountryCode;
