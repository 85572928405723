import { FC, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import Button from '../button/Button';
import Spinner from '../spinner/Spinner';

import { PdfViewerProps } from './types';
import workerSrc from '../../../pdf-worker';

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const PdfViewer: FC<PdfViewerProps> = ({
  pdfUrl,
  pageWidth,
  pageHeight,
  currentDocType
}) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const goToPrevPage = () =>
    setPageNumber((prevPage) => (prevPage - 1 <= 1 ? 1 : prevPage - 1));

  const goToNextPage = () =>
    setPageNumber((prevPage) =>
      prevPage + 1 >= numPages ? numPages : prevPage + 1
    );

  const renderLoader = () => (
    <div className="flex h-225 w-175 items-center justify-center">
      <Spinner />
    </div>
  );

  useEffect(() => setPageNumber(1), [currentDocType]);

  return (
    <div>
      {loading && renderLoader()}
      {!loading && numPages === 0 && (
        <div className="flex h-[90vh] w-175 items-center justify-center">
          Failed to load PDF.
        </div>
      )}
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages: num }) => {
          setNumPages(num);
          setLoading(false);
        }}
        onLoadError={() => {
          setLoading(false);
        }}
        loading={renderLoader()}
      >
        <Page
          pageNumber={pageNumber}
          width={pageWidth}
          height={pageHeight}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
      <div className="absolute bottom-0 left-1/2 flex w-full -translate-x-1/2 cursor-pointer items-center justify-between rounded-sm bg-white pb-6 text-sm transition-all ">
        <Button
          label="Previous"
          labelStyle="!text-content text-sm"
          className={`h-9 w-28 border border-grey px-3 py-2 ${
            pageNumber <= 1 ? 'cursor-not-allowed opacity-50' : ''
          }`}
          variant="transparent"
          leftIconName="left"
          handleClick={goToPrevPage}
        />
        {!loading && numPages > 0 && (
          <div className="font-medium text-content">
            Page {pageNumber} of {numPages || 1}
          </div>
        )}
        <Button
          label="Next"
          labelStyle="!text-content text-sm"
          className={`h-9 w-28 border border-grey px-3 py-2 ${
            pageNumber >= numPages ? 'cursor-not-allowed opacity-50' : ''
          }`}
          variant="transparent"
          rightIconName="right"
          handleClick={goToNextPage}
        />
      </div>
    </div>
  );
};
export default PdfViewer;
