import { useEffect, useState } from 'react';

import { formatISODate, getStartAndEndOfWeek } from '@utils/date';
import { DateRange, PickerProps } from '@types';

const WeekPicker = ({ handleDateRangeSelect }: PickerProps) => {
  const [selectedDate, setSelectedDate] = useState<DateRange | null>(null);

  useEffect(() => {
    const weekDates = getStartAndEndOfWeek();
    setSelectedDate(weekDates);
  }, []);

  useEffect(() => {
    if (selectedDate?.startDate && selectedDate?.startDate)
      handleDateRangeSelect(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <>
      <div className="text-sm font-normal text-grey">Start Date - End Date</div>
      <div className="mt-3 flex h-8.25 w-full items-center justify-between rounded-lg bg-grey10 px-3 text-sm text-content">
        <div>
          {selectedDate
            ? `${formatISODate({
                date: selectedDate.startDate,
                format: 'DD MMM YYYY'
              })} - ${formatISODate({
                date: selectedDate.endDate,
                format: 'DD MMM YYYY'
              })}`
            : ''}
        </div>
      </div>
    </>
  );
};

export default WeekPicker;
