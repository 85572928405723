import { useState } from 'react';

import { ReactComponent as CopyIcon } from '@assets/icons/copy.svg';
import Tooltip from '@components/tooltip/Tooltip';

interface CopyTextProps {
  textToCopy: string;
  textId?: string;
  className?: string;
  iconColor?: string;
  isDisabled?: boolean;
  onCopy?: () => void;
}

const CopyToClipboard = (props: CopyTextProps) => {
  const {
    textToCopy,
    textId = '',
    className = '',
    iconColor = 'text-borderGrey2',
    isDisabled,
    onCopy
  } = props;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent) => {
    setShowTooltip(true);
    navigator.clipboard.writeText(textToCopy);
    if (onCopy) onCopy();
    e.stopPropagation();
  };

  setTimeout(() => {
    if (showTooltip) setShowTooltip(false);
  }, 500);

  return (
    <div
      role="presentation"
      className={`group/tooltip relative ${
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${className}`}
      onClick={handleClick}
      data-tooltip-id={`copy-tooltip-${textId || textToCopy}`}
    >
      <CopyIcon
        className={`${iconColor} ${isDisabled ? '' : 'hover:text-grey'}`}
      />
      {!isDisabled && (
        <Tooltip
          id={`copy-tooltip-${textId || textToCopy}`}
          type="text"
          showTooltip={showTooltip}
          tooltipText="Copied!"
        />
      )}
    </div>
  );
};

export default CopyToClipboard;
