import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Mutex } from 'async-mutex';

import { API_URL, END_POINTS } from '@config';
import {
  clearAllLocalStorageItems,
  getLocalStorageItem,
  setTokensInStorage,
  StorageKeys
} from '@utils/storage';
import { ApiError, TokenResponse } from '@types';
import { AUTH, PAGE_NOT_FOUND } from '@constants/routes';

const mutex = new Mutex();

const handle403Error = (errorCode: string) => {
  if (
    [
      'ERR_BRANCH_FORBIDDEN',
      'ERR_GROUP_FORBIDDEN',
      'ERR_PERMISSION_FORBIDDEN'
    ].includes(errorCode)
  ) {
    window.location.replace(PAGE_NOT_FOUND);
  } else if (['ERR_ORGANISATION_FORBIDDEN'].includes(errorCode)) {
    clearAllLocalStorageItems();
    window.location.replace(AUTH.LOGIN);
  }
};

const handle500Error = (errorCode: string, userType: string) => {
  if (
    userType !== 'admin' &&
    ['ERR_ORGANISATION_NOT_FOUND', 'ERR_ORGANISATION_FORBIDDEN'].includes(
      errorCode
    )
  ) {
    clearAllLocalStorageItems();
    window.location.replace(AUTH.LOGIN);
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers) => {
    const token = getLocalStorageItem(StorageKeys.ACCESS_TOKEN);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = getLocalStorageItem(StorageKeys.REFRESH_TOKEN);
        const mobile = getLocalStorageItem('mobile');
        const refreshResult = await baseQuery(
          {
            url: END_POINTS.AUTH.REFRESH,
            method: 'POST',
            body: { refresh_token: refreshToken, mobile: String(mobile) }
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          setTokensInStorage(refreshResult.data as TokenResponse);
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          clearAllLocalStorageItems();
          window.location.replace(AUTH.LOGIN);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else if (result.error) {
    const error = result.error as ApiError;
    const errorCode = error.data?.errorCode;
    if (error.status === 403 && errorCode) {
      handle403Error(errorCode);
    } else if (error.status === 500 && errorCode) {
      const userType = getLocalStorageItem('userType');
      handle500Error(errorCode, userType);
    }
  }
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,
  tagTypes: ['Branches']
});
