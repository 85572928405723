import React from 'react';
import clsx from 'clsx';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from '@mui/material';

import { createTheme } from '@mui/material/styles';
import { TableProps } from 'types';

const CustomTable: React.FC<TableProps> = ({
  columns,
  data,
  isRowClickRequired = false,
  rowDataStyle,
  headerDataStyle,
  selectedRow,
  handleSelect,
  setLastRow,
  className,
  showNoResults = false,
  noResultText,
  disabledRowIds
}) => {
  const isRowSelected = (rowId: string) => {
    if (selectedRow === rowId) return true;
    return false;
  };

  const assignLastRowForInfiniteScroll = (
    node: HTMLTableRowElement | null,
    rowId: number
  ) => {
    if (data.length && data[data.length - 1].id === rowId) {
      setLastRow?.(node);
    }
  };

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: `'DM Sans', 'sans-serif'`
      }
    }
  });

  const isRowDisabled = (rowId: string) => disabledRowIds?.includes(rowId);

  const getTableRowStyle = (id: string) =>
    clsx(
      'group !border-b !border-b-greyStroke bg-white',
      isRowClickRequired && !isRowDisabled(id) ? 'cursor-pointer' : '',
      isRowDisabled(id)
        ? 'cursor-not-allowed bg-bgGrey70'
        : 'hover:bg-primaryLite8',
      isRowSelected(id) ? `!bg-primaryLite7` : 'inherit'
    );

  const getTableCellStyle = (columnStyle: string, id: string) =>
    clsx(
      '!text-base',
      columnStyle || '',
      rowDataStyle || '',
      isRowDisabled(id) ? '!text-bgGrey60' : ''
    );

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        className={`table-scroll h-full bg-white ${className || ''}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow className="h-11 leading-5">
              {columns.map((column) => (
                <TableCell
                  className={`!border-b !border-b-greyStroke !bg-grey10 !text-base !font-medium !text-grey ${
                    headerDataStyle || ''
                  }`}
                  key={column.accessor}
                  size="small"
                >
                  {column.Header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showNoResults ? (
              <TableRow
                className={`group !border-b !border-b-greyStroke bg-white hover:bg-primaryLite8 ${
                  isRowClickRequired ? 'cursor-pointer' : ''
                }`}
              >
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  className="!text-sm font-normal italic !text-grey"
                >
                  {noResultText || 'No data to show'}
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row) => (
                <TableRow
                  key={row.id}
                  ref={(node) => assignLastRowForInfiniteScroll(node, row.id)}
                  selected={
                    isRowClickRequired && !isRowDisabled(row.id)
                      ? isRowSelected(row.id)
                      : false
                  }
                  onClick={() => {
                    if (!isRowDisabled(row.id) && handleSelect)
                      handleSelect(row);
                  }}
                  className={getTableRowStyle(row.id)}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={`${row.id}_${column.accessor}`}
                      className={getTableCellStyle(column.style || '', row.id)}
                      width={column.width || ''}
                    >
                      {row[column.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default CustomTable;
