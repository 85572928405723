import DummyImage from '@assets/images/flag-india.svg';
import { SettlementMode } from '@constants/generic';
import { ImageForm } from '@types';

interface DummyGroup {
  // to be removed
  id: number;
  image_url: string;
}
export interface Branch {
  id: string;
  groups?: DummyGroup[]; // to be updated
  group_count: number;
  image_url: string;
  name: string;
}

// To do - to be removed on integration
export const dummyBranchList = [
  {
    id: '1',
    name: 'Main Branch1',
    image_url: DummyImage,
    group_count: 6
  },
  {
    id: '2',
    name: 'Latest Branch1',
    image_url: '',
    group_count: 0,
    groups: []
  },
  {
    id: '11',
    name: 'Main Branch2',
    image_url: DummyImage,
    group_count: 6
  },
  {
    id: '12',
    name: 'Latest Branch2',
    image_url: '',
    group_count: 0,
    groups: []
  },
  {
    id: '21',
    name: 'Main Branch3',
    image_url: DummyImage,
    group_count: 6
  },
  {
    id: '22',
    name: 'Latest Branch3',
    image_url: '',
    group_count: 0,
    groups: []
  }
];

export interface UpdateBranchRequest {}

export interface BranchInfo {
  branchId: string;
  branchName: string;
  branchImage?: string | File;
}

export interface BranchForm {
  branchName: string;
  branchImage?: ImageForm;
  settlementMode: SettlementMode.UPI | SettlementMode.BANK;
}

export interface UpiAccountData {
  upiId: string;
}

export interface BankAccountData {
  accountNumber: string;
  confirmAccountNumber: string;
  ifscCode: string;
}

export type AccountData = UpiAccountData | BankAccountData;

const roleOptions = [
  { id: 1, label: 'Organization Admin', value: 'organisation_admin' },
  { id: 2, label: 'Branch Admin', value: 'branch_admin' },
  { id: 3, label: 'Group Admin', value: 'group_admin' },
  { id: 4, label: 'Finance', value: 'finance' }
];

const userStatusOptions = [
  { id: 5, label: 'Pending', value: 'pending' },
  { id: 6, label: 'Rejected', value: 'rejected' }
];

export const teamManagementInvitedMemberTableFilter = {
  userStatus: {
    id: 1,
    label: 'User Status',
    field: 'userStatus',
    options: userStatusOptions
  },
  role: {
    id: 2,
    label: 'Role',
    field: 'role',
    options: roleOptions
  }
};

export const teamManagementUserRolesTableFilter = {
  role: {
    id: 1,
    label: 'Role',
    field: 'role',
    options: roleOptions
  }
};

export interface OrgAndBranchId {
  organizationId: string;
  branchId: string;
}

export interface OrgBranchGroupIds extends OrgAndBranchId {
  groupId: string;
}

export interface OrgBranchGroupMemberIds extends OrgBranchGroupIds {
  memberId: string;
}

export interface UpdateGroupStatusParams extends OrgBranchGroupIds {
  status: string;
}

export interface OrgBranchOrderIds extends OrgAndBranchId {
  orderId: string;
}
