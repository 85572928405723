import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountInfo: {}
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAccountInfo: (state, action: PayloadAction<any>) => {
      state.accountInfo = action.payload;
    },
    resetAuthState: () => initialState
  }
});

export const { updateAccountInfo, resetAuthState } = authSlice.actions;
export default authSlice.reducer;
