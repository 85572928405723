export const bytesFileSize = 1000000;

export const allowedCSVSizeForGroupCreation = 2 * bytesFileSize; // MB

export const allowedDocumentFileSize = 5 * bytesFileSize; // MB

export const allowedImageSize = 5 * bytesFileSize; // MB

export const allowedImageSizeLabel = '5 MB';

export const acceptedAttachmentFormats =
  'image/jpeg, image/png, image/jpg, video/*';
