import { DASHBOARD } from '@constants/routes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Dashboard = lazy(() => import('@features/dashboard/pages/Dashboard'));

export const DashboardRoutes: RouteObject = {
  path: DASHBOARD,
  element: <Dashboard />
};

export default DashboardRoutes;
