import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse } from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSubscription: builder.query<
      HTTPResponse<any>,
      { organizationId: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.SUBSCRIPTION.GET_CURRENT_SUBSCRIPTION(
          req.organizationId
        )}`,
        method: 'GET'
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const { useLazyGetCurrentSubscriptionQuery } = extendedBaseQuery;
