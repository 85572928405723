import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { TRANSACTIONS } from '@constants/routes';

const Transactions = lazy(
  () => import('@features/transactions/pages/transactions/Transactions')
);

export const TransactionsRoutes: RouteObject[] = [
  {
    path: TRANSACTIONS.TRANSACTIONS,
    element: <Transactions />
  }
];

export default TransactionsRoutes;
