import { KeyboardEvent } from 'react';
import clsx from 'clsx';

import { ReactComponent as RupeeIcon } from '@assets/icons/rupee-medium.svg';
import { AmountInputProps } from 'types/input';
import { sanitizeNumberInput } from '@utils/generic';

import { inputStyles, sizeStyle } from '../input/input.config';

const AmountInput = (props: AmountInputProps) => {
  const {
    name,
    type = 'text',
    inputClassNames,
    inputSize = 'medium',
    value,
    disabled,
    placeholder = '0',
    onAmountChange,
    maxAmount,
    error,
    errorMessage,
    handleInputBlur,
    ...rest
  } = props;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    // Prevent the default behavior for arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    // Prevent value incrementing / decrementing on scroll
    e.preventDefault();
    e.currentTarget.blur();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = sanitizeNumberInput(event.target.value);
    let formattedAmount = amount;
    if (
      amount !== '' &&
      (Number(formattedAmount) <= 0 ||
        Number(formattedAmount) > Number(maxAmount))
    ) {
      formattedAmount = '';
      return;
    }
    formattedAmount = amount;
    onAmountChange(formattedAmount);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (handleInputBlur) {
      const inputValue = e.target.value.trim();
      handleInputBlur(inputValue);
    }
  };

  return (
    <div
      className={`${
        inputStyles.common
      } relative flex items-center rounded-lg px-4 py-2.5 ${
        error ? `${inputStyles.error}` : 'hover:focus-within:border-primary'
      } ${sizeStyle[inputSize]}`}
    >
      <div className="flex h-full items-center justify-center ">
        <RupeeIcon className="h-5 w-3.5" />
      </div>
      <div className="h-9 border border-transparent border-r-grey40 pr-3.5" />
      <input
        id={name}
        name={name}
        type={type}
        className={clsx(
          inputClassNames,
          inputStyles,
          'no-spinners w-full bg-transparent pl-3 focus:outline-none'
        )}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onWheel={handleWheel}
        onChange={handleInputChange}
        onBlur={onInputBlur}
        {...rest}
      />
      {error && errorMessage && (
        <div className="absolute -bottom-5 left-0 flex items-center text-xs text-error">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default AmountInput;
