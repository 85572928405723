import { ReactComponent as InfoIcon } from '@assets/icons/info.svg';

interface InfoCardProps {
  primaryText: string;
  secondaryText?: string;
  onClickSecondaryText?: () => void;
}

const InfoCard = ({
  primaryText,
  secondaryText,
  onClickSecondaryText
}: InfoCardProps) => (
  <div className="border-bordergrey2 mb-6 flex h-12 w-full items-center justify-between rounded-md border border-l-6 border-l-errorLite3 bg-white p-4">
    <div className="flex items-center gap-3 text-base font-normal text-errorLite3">
      <InfoIcon />
      {primaryText}
    </div>
    {secondaryText && (
      <div
        role="presentation"
        onClick={onClickSecondaryText}
        className="cursor-pointer text-base font-normal text-primary"
      >
        {secondaryText}
      </div>
    )}
  </div>
);

export default InfoCard;
