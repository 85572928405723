import { useRoutes, RouteObject } from 'react-router-dom';

import { getLocalStorageItem } from '@utils/storage';
import { UserTypes } from '@constants/role';

import { mainRoutes, adminRoutes } from './routes';

const AppRoutes = () => {
  const routesConfig: RouteObject[] =
    getLocalStorageItem('userType') === UserTypes.ADMIN
      ? adminRoutes
      : mainRoutes;

  const Routes = useRoutes([...routesConfig]);

  return Routes;
};

export default AppRoutes;
