import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useNotify from '@hooks/notify';
import { setCurrentOrg } from '@layouts/main-header/userSlice';
import { Button, ImageUpload, Input, Label } from '@components';
import {
  AccountType,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  imageInitialState
} from '@constants/generic';
import { nameRegex } from '@constants/regex';
import { getNameErrorMessage, isMinLengthSatisfied } from '@utils/generic';
import { isInvalidEntityName } from '@utils/validations';
import { ApiError, ImageForm } from '@types';
import { Org } from 'types/organization';
import {
  useLazyGetOrganizationListQuery,
  useUpdateOrganizationByIdMutation
} from '@features/authentication/queries/AuthQuery';
import {
  useImageDeleteMutation,
  useImageUploadMutation
} from '@features/group-management/queries/GroupQuery';

interface EditOrganizationProps {
  imageUrl: string;
  currentOrgName: string;
  currentOrgId: string;
  handleSave: () => void;
}

const EditOrganization = ({
  imageUrl,
  currentOrgName,
  currentOrgId,
  handleSave
}: EditOrganizationProps) => {
  const [deleteLogo] = useImageDeleteMutation();
  const [updateLogo] = useImageUploadMutation();
  const [updateOrg] = useUpdateOrganizationByIdMutation();
  const [getOrgList] = useLazyGetOrganizationListQuery();
  const { createSuccessAlert, createErrorAlert } = useNotify();
  const dispatch = useDispatch();

  const [orgImage, setOrgImage] = useState<ImageForm>(imageInitialState);
  const [orgName, setOrgName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSaveDisabled = isInvalidEntityName(orgName);
  const hasChanges =
    orgName !== currentOrgName ||
    orgImage.preview ||
    (imageUrl && !orgImage.image_url && !orgImage.preview);

  const onImageChange = (image: ImageForm) => {
    setOrgImage(image);
  };

  const onImageClear = () => {
    setOrgImage(imageInitialState);
  };

  const onChangeOrgName = (value: string) => {
    setNameError('');
    setOrgName(value);
  };

  const onBlurOrgName = (value: string) => {
    if (!isMinLengthSatisfied(value)) setNameError(getNameErrorMessage());
  };

  const handleUpdateComplete = async () => {
    const resp = await getOrgList(null);
    if ('data' in resp) {
      const currentOrg = resp.data?.find((org: Org) => org.id === currentOrgId);
      dispatch(setCurrentOrg(currentOrg));
    }
    setIsLoading(false);
    handleSave();
  };

  const onDeleteOrgLogo = async () => {
    const payload = {
      type: AccountType.ORGANIZATION,
      id: currentOrgId
    };

    const res = await deleteLogo({ body: payload });
    if ('error' in res) createErrorAlert('Organization logo delete failed');
    else createSuccessAlert('Organization logo deleted successfully');

    handleUpdateComplete();
  };

  const onUpdateOrgLogo = async () => {
    if (orgImage?.file) {
      const imageData = new FormData();
      imageData.append('image', orgImage?.file);
      imageData.append('id', currentOrgId);
      imageData.append('type', AccountType.ORGANIZATION);

      const res = await updateLogo(imageData);

      if ('error' in res) {
        const error = res.error as ApiError;
        createErrorAlert(
          error?.data?.message || 'Organization logo upload failed'
        );
      } else createSuccessAlert('Organization logo uploaded successfully');

      handleUpdateComplete();
    }
  };

  const onUpdateOrgName = async () => {
    const payload = {
      body: { name: orgName },
      orgId: currentOrgId
    };

    const res = await updateOrg(payload);

    if ('error' in res) {
      const error = res.error as ApiError;
      createErrorAlert(
        error?.data?.message || 'Organization name update failed'
      );
    } else createSuccessAlert('Organization name updated successfully');

    handleUpdateComplete();
  };

  const handleOrgSave = () => {
    setIsLoading(true);

    if (imageUrl && !orgImage.image_url && !orgImage.preview) onDeleteOrgLogo();
    else if (orgImage.preview) onUpdateOrgLogo();

    if (orgName !== currentOrgName) onUpdateOrgName();
  };

  useEffect(() => {
    setOrgImage({
      file: undefined,
      preview: '',
      image_url: imageUrl
    });
  }, [imageUrl]);

  useEffect(() => {
    setOrgName(currentOrgName);
  }, [currentOrgName]);

  return (
    <div className="flex w-118 flex-col gap-6 p-6">
      <div className="text-20 font-medium leading-7 text-content">
        Organization Details
      </div>
      <div>
        <ImageUpload
          label="Organization Logo"
          imageSrc={orgImage.image_url || orgImage.preview}
          onImageChange={onImageChange}
          showDeleteIcon={!!(orgImage.image_url || orgImage.preview)}
          onClearImage={onImageClear}
        />
      </div>
      <div>
        <Label label="Organization Name" />
        <Input
          placeholder="Organization Name"
          value={orgName}
          className="mt-4"
          inputRegex={nameRegex}
          handleInputChange={onChangeOrgName}
          error={!!nameError}
          errorMessage={nameError}
          handleInputBlur={onBlurOrgName}
          minLength={NAME_MIN_LENGTH}
          maxLength={NAME_MAX_LENGTH}
        />
      </div>
      <div>
        <Button
          label="Save"
          handleClick={handleOrgSave}
          disabled={isSaveDisabled || !hasChanges}
          className="w-full"
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default EditOrganization;
