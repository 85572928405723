import { useRef } from 'react';

import { initMixpanel } from '@core/analytics';

import AppRoutes from './routes';
import AppProvider from './contexts/app';

const App = () => {
  const isMixPanelInitiated = useRef<boolean>(false);
  if (!isMixPanelInitiated.current) {
    initMixpanel();
    isMixPanelInitiated.current = true;
  }

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};

export default App;
