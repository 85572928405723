import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GroupType } from '../constants';

interface GroupInfo {
  frequency: string;
  interval: number;
  name: string;
  groupAmount: number;
  dueDate: string;
}

interface ListParams {
  filter: GroupType;
  search: string;
}

interface GroupState {
  currentGroupInfo: GroupInfo;
  listParams: ListParams;
}

const initialState: GroupState = {
  currentGroupInfo: {
    frequency: '',
    interval: 0,
    name: '',
    groupAmount: 0,
    dueDate: ''
  },
  listParams: { filter: GroupType.ACTIVE, search: '' }
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    updateGroupInfo: (state, action: PayloadAction<GroupInfo>) => {
      state.currentGroupInfo = action.payload;
    },
    updateListParams: (state, action: PayloadAction<ListParams>) => {
      state.listParams = action.payload;
    },
    resetGroupState: () => initialState
  }
});

export const { updateGroupInfo, updateListParams, resetGroupState } =
  groupSlice.actions;
export default groupSlice.reducer;
