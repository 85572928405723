import { PageIndicatorProps } from 'types/pagination';

const PageIndicator = ({ page, totalCount, limit }: PageIndicatorProps) => {
  const pageCount = Math.ceil(totalCount / limit);

  return (
    <div className="text-sm font-medium text-content">{`Page ${page} of ${pageCount}`}</div>
  );
};

export default PageIndicator;
