import ChoosePlanImg from '@assets/images/choose-plan.svg';

const ChoosePlanPage = ({ name }: { name: string }) => (
  <div className="flex h-[calc(100vh-83px)] w-full flex-col items-center justify-center">
    <img src={ChoosePlanImg} alt="thankyou img" className=" h-60 w-122" />
    <div className="mt-10 text-3xl font-bold text-content">
      Hey {name}, Seems you haven&apos;t selected a plan
    </div>
    <div className="mt-4 flex max-w-200 justify-center text-center text-xl text-grey">
      Plan selection isn’t available on the web. Please use our mobile app to
      choose a plan and then return here to continue.
    </div>
  </div>
);

export default ChoosePlanPage;
