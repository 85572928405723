import NoResultImage from '@assets/images/no-result.svg';
import { EmptyScreenProps } from '@types';

import Button from '../button/Button';

const EmptyScreen = ({
  title = 'Results not found',
  description = 'We couldn’t find what you’re looking for',
  className = '',
  handleClick,
  emptyScreenImage,
  buttonLabel,
  variant
}: EmptyScreenProps) => {
  const buttonVariant = variant || 'secondary';

  return (
    <div
      className={`flex size-full flex-col items-center justify-center ${className}`}
    >
      <img src={emptyScreenImage || NoResultImage} alt="empty-screen" />
      <div className="mt-8 text-2xl font-bold">{title}</div>
      {!!description && (
        <div className="mt-2 text-sm font-normal text-grey ">{description}</div>
      )}
      {buttonLabel && (
        <Button
          variant={buttonVariant}
          label={buttonLabel}
          size="large"
          handleClick={handleClick}
          className="my-8"
        />
      )}
    </div>
  );
};

export default EmptyScreen;
