import { SUBSCRIPTION_PLAN } from '@constants/routes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const SubscriptionPlan = lazy(
  () => import('@features/subscription/pages/SubscriptionPlan')
);
export const ProfileRoutes: RouteObject = {
  path: SUBSCRIPTION_PLAN,
  element: <SubscriptionPlan />
};
export default ProfileRoutes;
