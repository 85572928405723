import { TimeZones, months } from '@constants/date';

export const isLastDayOfMonth = (date: Date): boolean => {
  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);
  return nextDay.getDate() === 1;
};

export const isFirstDayOfMonth = (date: Date): boolean => date.getDate() === 1;

export const getStartAndEndOfWeek = () => {
  const today = new Date();
  const startOfWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 6
  );
  const endOfWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  return { startDate: startOfWeek, endDate: endOfWeek };
};

export const getCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  return currentMonth;
};

export type DateFormat =
  | 'dd-mm-yyyy' // 16-01-2024
  | 'yyyy-mm-dd' // 2024-01-16
  | 'dd-mm-yyyy, hh:mm' // 16-01-2024, 13:01
  | 'DD MMM YYYY' // 16 Jan 2024
  | 'DD MMMM YYYY' // 16 January 2024
  | 'MMMM YYYY' // January 2024
  | 'dd-mm-yy' // 16-01-24
  | 'DD MMM' // 16 Jan
  | 'DD MMM YYYY at hh:mm am/pm'; // 16 Jan 2024 at 13:01 am

// i/p date  yyyy-mm-ddTHH:mm:ssZ
export const formatISODate = ({
  date,
  separator = '/',
  format = 'dd-mm-yyyy'
}: {
  date: string | Date;
  separator?: string;
  format?: DateFormat;
}) => {
  if (!date) return '';
  const formattedDate = typeof date === 'string' ? new Date(date) : date;
  const year = formattedDate.getFullYear();
  const shortYear = String(year).slice(-2);
  const monthIndex = formattedDate.getMonth();
  const derivedDate = formattedDate.getDate();
  let hours = formattedDate.getHours();
  const dayPeriod = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12;
  const minutes =
    formattedDate.getMinutes() < 10
      ? `0${formattedDate.getMinutes()}`
      : formattedDate.getMinutes();
  const currentDate = derivedDate < 10 ? `0${derivedDate}` : `${derivedDate}`;

  switch (format) {
    case 'dd-mm-yyyy': {
      const monthNumber = months[monthIndex].numeral;
      return `${currentDate}${separator}${monthNumber}${separator}${year}`;
    }
    case 'yyyy-mm-dd': {
      const monthNumber = months[monthIndex].numeral;
      return `${year}${separator}${monthNumber}${separator}${currentDate}`;
    }
    case 'dd-mm-yyyy, hh:mm': {
      const monthNumber = months[monthIndex].numeral;
      return `${currentDate}${separator}${monthNumber}${separator}${year}, ${hours}:${minutes}`;
    }
    case 'DD MMM YYYY': {
      const monthShortName = months[monthIndex].shortName;
      return `${currentDate} ${monthShortName} ${year}`;
    }
    case 'DD MMMM YYYY': {
      const monthName = months[monthIndex].name;
      return `${currentDate} ${monthName} ${year}`;
    }
    case 'MMMM YYYY': {
      const monthName = months[monthIndex].name;
      return `${monthName} ${year}`;
    }
    case 'dd-mm-yy': {
      const monthNumber = months[monthIndex].numeral;
      return `${currentDate}${separator}${monthNumber}${separator}${shortYear}`;
    }
    case 'DD MMM YYYY at hh:mm am/pm': {
      const monthShortName = months[monthIndex].shortName;
      return `${monthShortName} ${currentDate} ${year} at ${hours}:${minutes} ${dayPeriod}`;
    }
    case 'DD MMM': {
      const monthShortName = months[monthIndex].shortName;
      return `${currentDate} ${monthShortName}`;
    }
    default:
      return '';
  }
};

export const getOrdinalDateString = (dayOfMonthString: string) => {
  const dayOfMonth = parseInt(dayOfMonthString, 10);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(dayOfMonth) || dayOfMonth < 1 || dayOfMonth > 31) {
    return 'Invalid day of month';
  }

  let ordinalIndicator;

  // Determine the ordinal indicator based on the last digit of the day
  if (dayOfMonth >= 11 && dayOfMonth <= 13) {
    ordinalIndicator = 'th';
  } else {
    switch (dayOfMonth % 10) {
      case 1:
        ordinalIndicator = 'st';
        break;
      case 2:
        ordinalIndicator = 'nd';
        break;
      case 3:
        ordinalIndicator = 'rd';
        break;
      default:
        ordinalIndicator = 'th';
    }
  }

  return `${dayOfMonth}${ordinalIndicator}`;
};

// param - month index
// Return the last day of param month if provided,
// else return the last day of current month
export const getLastDayOfMonthUTC = (month?: number) => {
  const currentDate = new Date();
  const monthIndex = month || currentDate.getUTCMonth();
  currentDate.setUTCMonth(monthIndex + 1, 1);
  currentDate.setUTCDate(currentDate.getUTCDate() - 1);
  currentDate.setUTCHours(0, 0, 0, 0);

  return currentDate;
};

export function isFutureOrCurrentDay(dayString: string) {
  const day = parseInt(dayString, 10);

  const currentDate = new Date();
  const currentDayOfMonth = currentDate.getDate();

  return day >= currentDayOfMonth;
}

export function isFutureDay(dayString: string) {
  const day = parseInt(dayString, 10);

  const currentDate = new Date();
  const currentDayOfMonth = currentDate.getDate();

  return day > currentDayOfMonth;
}

export function getNextMonthDate(dayString: string) {
  const day = parseInt(dayString, 10);

  const currentDate = new Date();

  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    day
  );

  return nextMonthDate.setHours(0, 0, 0, 0);
}

export function getCurrentMonthDate(dayString: string) {
  const day = parseInt(dayString, 10);
  const currentDate = new Date();
  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    day
  );

  return nextMonthDate.setHours(0, 0, 0, 0);
}

export const isFutureDayOfWeek = (dayValue: number) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  if (dayValue > currentDay) {
    return true;
  }
  return false;
};

export const isCurrentOrFutureDayOfWeek = (dayValue: number) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  if (dayValue >= currentDay) {
    return true;
  }
  return false;
};

export const getDateOfCurrentWeekDay = (dayValue: number) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const daysToAdd = (dayValue - currentDay + 7) % 7;
  currentDate.setDate(currentDate.getDate() + daysToAdd);
  return currentDate;
};

export const getDateOfNextWeek = (dayValue: number): Date => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const daysToAdd = (dayValue - currentDay) % 7;
  const daysUntilNextSunday = daysToAdd + (dayValue <= currentDay ? 7 : 0);

  currentDate.setDate(currentDate.getDate() + daysUntilNextSunday);
  return currentDate;
};

export const formatTime = (time: number): string => {
  // Format seconds as MM:SS
  const minutes = Math.floor(time / 60);
  const remainingSeconds = time % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const isSameDate = (date1: string, date2: string) => {
  const dateA = new Date(date1);
  const dateB = new Date(date2);
  return dateA.toISOString() === dateB.toISOString();
};

export const addOneMonth = (date: Date) =>
  new Date(
    new Date(date).setMonth(new Date(date).getMonth() + 1)
  ).toISOString();

export const addOneWeek = (date: Date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 7);
  return newDate.toISOString();
};

// i/p: date = xx/yy/zzzz, day = aa
// o/p: aa/yy/zzzz
export const changeDayInGivenDate = (date: Date, day: string) =>
  new Date(new Date(new Date(date).setDate(Number(day))).setHours(0, 0, 0, 0));

// checks if given date as argument is same as the current date
export const checkIfDateIsCurrentDate = (date: Date) => {
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const formattedInputDate = new Date(new Date(date).setHours(0, 0, 0, 0));
  return isSameDate(
    currentDate.toISOString(),
    formattedInputDate.toISOString()
  );
};

export const checkIfDateIsPastDate = (date: Date) => {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const inputDate = new Date(date).setHours(0, 0, 0, 0);
  return currentDate > inputDate;
};

export const getStartAndEndDateOfCurrentMonth = (): {
  startDate: Date;
  endDate: Date;
} => {
  const now = new Date();
  const startDate = new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
  const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
  return { startDate, endDate };
};

const formatNumber = (value: number) => (value < 10 ? `0${value}` : value);

export const getFormattedDateTimeWithOffset = (
  date: Date | string,
  offset: TimeZones,
  isEndDate = false
) => {
  const newDate = new Date(date);
  if (isEndDate) newDate.setHours(23, 59, 59, 999);
  else newDate.setHours(0, 0, 0, 0);

  const absOffset = Math.abs(offset);
  const offsetFormatted = `${offset >= 0 ? '+' : '-'}${formatNumber(
    Math.floor(absOffset / 60)
  )}${formatNumber(absOffset % 60)}`;

  const newFormattedDate = new Date(
    `${newDate.toString().split('GMT')[0]} GMT${offsetFormatted}`
  );

  return new Date(newFormattedDate.toUTCString()).toISOString();
};

export const getStartAndEndDateOfLastMonth = (): {
  startDate: Date;
  endDate: Date;
} => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  // If the current month is January (0)
  // set lastMonth to December (11) of the previous year
  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  const startDate = new Date(lastMonthYear, lastMonth, 1);
  const endDate = new Date(currentYear, currentMonth, 0);

  return { startDate, endDate };
};

export const getStartAndPresentDateOfCurrentMonth = (): {
  startDate: Date;
  endDate: Date;
} => {
  const now = new Date();
  const startDate = new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
  const endDate = new Date(); // Current Date
  return { startDate, endDate };
};

const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

export const getLastNDays = (
  n: number
): { startDate: string; endDate: string } => {
  const now = new Date();
  const startDate = new Date();
  startDate.setDate(now.getDate() - (n - 1));
  return { startDate: formatDate(startDate), endDate: formatDate(now) };
};

export const getNumberOfDaysBetweenTwoDates = (
  startDate: Date,
  endDate: Date
): number => {
  // Convert both dates to milliseconds
  const startMillis = startDate.getTime();
  const endMillis = endDate.getTime();

  // Calculate the difference in milliseconds
  const diffMillis = endMillis - startMillis;

  // Convert milliseconds to days
  const diffDays = diffMillis / (1000 * 60 * 60 * 24); // milliseconds * seconds * minutes * hours

  return Math.ceil(diffDays) + 1;
};

export const getLastYearDate = () => {
  const currentDate = new Date();
  const lastYearDate = new Date(currentDate);
  lastYearDate.setFullYear(currentDate.getFullYear() - 1);
  return lastYearDate;
};
