import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { HELP } from '@constants/routes';

const HelpPage = lazy(() => import('@features/help/pages/HelpPage'));

export const HelpRoutes: RouteObject[] = [
  {
    path: HELP,
    element: <HelpPage />
  }
];

export default HelpRoutes;
