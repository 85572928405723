import { Icon, Tooltip } from '@components';

import theme from '../../../themes/colors';

interface AmountInputTooltipProps {
  maxAmount: string;
  minAmount: string;
  tooltipId: string;
}

const AmountInputTooltip = ({
  maxAmount,
  minAmount,
  tooltipId
}: AmountInputTooltipProps) => (
  <>
    <Icon
      name="alert-circle"
      stroke={theme.subText}
      data-tooltip-id={`${tooltipId}_max-amount-icon`}
    />

    <Tooltip
      id={`${tooltipId}_max-amount-icon`}
      type="text"
      tooltipText={`Allowed amount is between ${minAmount} and ${maxAmount}`}
      className="leading-5"
    />
  </>
);

export default AmountInputTooltip;
