import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ADMIN_PAGE_NOT_FOUND, PAGE_NOT_FOUND } from '@constants/routes';

const PageNotFound = lazy(
  () => import('@features/page-not-found/pages/page-not-found/PageNotFound')
);

export const PageNotFoundRoutes: RouteObject[] = [
  {
    path: PAGE_NOT_FOUND,
    element: <PageNotFound />
  }
];

export const AdminPageNotFoundRoutes: RouteObject[] = [
  {
    path: ADMIN_PAGE_NOT_FOUND,
    element: <PageNotFound />
  }
];

export default PageNotFoundRoutes;
