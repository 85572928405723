import { useEffect, useState } from 'react';

import { formatISODate } from '@utils/date';
import { PickerProps } from '@types';

const Yesterday = ({ handleDateRangeSelect }: PickerProps) => {
  const [selectedDate, setSelectedDate] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);

  const getYesterdayDates = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const startOfDay = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      0,
      0,
      0
    );
    const endOfDay = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59
    );

    return { startDate: startOfDay, endDate: endOfDay };
  };

  useEffect(() => {
    const yesterdayDates = getYesterdayDates();
    setSelectedDate(yesterdayDates);
  }, []);

  useEffect(() => {
    if (selectedDate?.startDate && selectedDate?.startDate)
      handleDateRangeSelect(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <>
      <div className="text-sm font-normal text-grey">Date Selected</div>
      <div className="mt-3 flex h-8.25 w-full items-center justify-between rounded-lg bg-grey10 px-3 text-sm text-content">
        <div>
          {selectedDate
            ? formatISODate({
                date: selectedDate.startDate,
                format: 'DD MMM YYYY'
              })
            : ''}
        </div>
      </div>
    </>
  );
};

export default Yesterday;
