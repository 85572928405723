import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { renderToString } from 'react-dom/server';

import EmptyScreen from '@components/empty-screen/EmptyScreen';
import Spinner from '@components/spinner/Spinner';
import Icon from '@components/icon/Icon';
import GreenDot from '@assets/images/green-dot.svg';
import LightGreenDot from '@assets/images/light-green-dot.svg';
import NoResultImage from '@assets/images/no-payment-graph.svg';
import useCheckPermission from '@hooks/checkPermission';
import UserPermissionTypes from '@constants/permissions';
import { hasNonZeroValue } from '@utils/generic';
import { MultiBarChartProps } from '@types';

import theme from '../../../../themes/colors';

const MultiBarChart: React.FC<MultiBarChartProps> = ({
  title,
  filterValue,
  setFilterValue,
  primaryBarLabel,
  secondaryBarLabel,
  xAxisValues,
  primaryBarValues,
  secondaryBarValues,
  handleDownloadReport,
  isLoading,
  collectionStartedAt
}) => {
  const minFilterValue = new Date(collectionStartedAt).getFullYear();
  const maxFilterValue = new Date().getFullYear();

  const isMaxFilterValue = filterValue === maxFilterValue;
  const isMinFilterValue = filterValue === minFilterValue;

  const addFilterValue = () => {
    if (filterValue && setFilterValue)
      if (filterValue < maxFilterValue) setFilterValue(filterValue + 1);
  };

  const reduceFilterValue = () => {
    if (filterValue && setFilterValue)
      if (filterValue > minFilterValue) setFilterValue(filterValue - 1);
  };

  const hasPaymentReport =
    hasNonZeroValue(primaryBarValues) || hasNonZeroValue(secondaryBarValues);

  const { checkPermission } = useCheckPermission();

  const hasDownloadReportAccess = checkPermission([
    UserPermissionTypes.PAYMENT_ORDER_GENERATE_REPORT
  ]);

  const options: ApexOptions = {
    chart: {
      id: 'multiBarGraph',
      type: 'bar',
      zoom: {
        enabled: true
      },
      toolbar: {
        show: false
      }
    },
    states: {
      hover: {
        filter: {
          type: 'darken'
        }
      }
    },
    grid: {
      strokeDashArray: 2
    },
    legend: {
      onItemHover: {
        highlightDataSeries: false
      }
    },
    tooltip: {
      custom: ({ series, dataPointIndex }) =>
        renderToString(
          <div className="w-40">
            <div className="bg-primaryLite px-3 py-2 text-xs">
              {`${xAxisValues[dataPointIndex]} ${filterValue}`}
            </div>
            <div className="bg-content py-2 text-theme">
              <div className="flex items-center justify-between gap-2  px-3 ">
                <div className="flex items-center justify-center gap-2">
                  <div>
                    <img src={GreenDot} alt="" />
                  </div>
                  <div>{primaryBarLabel}</div>
                </div>
                <div>{series[0][dataPointIndex]}</div>
              </div>
              <div className="flex items-center justify-between gap-2 px-3 ">
                <div className="flex items-center justify-center gap-2">
                  <div>
                    <img src={LightGreenDot} alt="" />
                  </div>
                  <div>{secondaryBarLabel}</div>
                </div>
                <div>{series[1][dataPointIndex]}</div>
              </div>
            </div>
          </div>
        )
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end'
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: [theme.primary, theme.primaryLite],
    xaxis: {
      labels: {
        style: {
          colors: theme.grey
        }
      },
      categories: xAxisValues,
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.grey
        }
      }
    }
  };

  return (
    <div className="flex h-full flex-col items-center justify-start rounded-xl bg-theme p-6">
      <div className="mb-4 flex w-full items-center justify-between">
        <div className="text-xl font-medium">{title}</div>
        <div className="flex gap-3">
          {hasDownloadReportAccess && hasPaymentReport && (
            <div
              className="flex cursor-pointer items-center justify-center gap-2 text-sm font-normal text-primary"
              role="presentation"
              onClick={handleDownloadReport}
            >
              <div>Download Report</div>
              <Icon
                name="download"
                size="none"
                className="size-3.5"
                stroke={theme.primary}
              />
            </div>
          )}
          {filterValue && (
            <div className="flex w-25 items-center justify-center gap-2 rounded-xl bg-grey10 p-2.5 text-xs">
              <Icon
                name="left"
                size="none"
                role="presentation"
                onClick={reduceFilterValue}
                className={`size-4 ${
                  isMinFilterValue
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer'
                }`}
              />
              <div>{filterValue}</div>
              <Icon
                name="right"
                size="none"
                role="presentation"
                onClick={addFilterValue}
                className={`size-4 ${
                  isMaxFilterValue
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer'
                }`}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="flex min-h-450 items-center justify-center">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <div className="w-full">
          {hasPaymentReport ? (
            <div className="w-full">
              <ReactApexChart
                type="bar"
                height={400}
                options={options}
                width="100%"
                series={[
                  {
                    name: primaryBarLabel,
                    data: primaryBarValues
                  },
                  {
                    name: secondaryBarLabel,
                    data: secondaryBarValues
                  }
                ]}
              />
            </div>
          ) : (
            <div className="flex min-h-450 w-full items-center justify-center">
              <EmptyScreen
                title="No payment yet!"
                description="After payment is done, your graph will update automatically"
                emptyScreenImage={NoResultImage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiBarChart;
