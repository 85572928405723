import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PendingVerificationImage from '@assets/images/pending-verification.svg';
import { sendMixpanelEvent, trackPageView, Event } from '@core/analytics';
import { cofeeSupportMail, cofeeSupportMailSubject } from '@constants/cofee';

const PendingVerification = () => {
  const { currentOrg } = useSelector((state: any) => state.user);

  const onClickContactSupport = () => {
    sendMixpanelEvent(Event.CONTACT_SUPPORT_CLICKED, {
      organization_name: currentOrg.name
    });
  };

  useEffect(() => {
    if (currentOrg?.id)
      trackPageView('Pending Verification', {
        organization_name: currentOrg.name
      });
  }, [currentOrg]);

  return (
    <>
      <div className="flex h-[calc(100vh-300px)] flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <img
            src={PendingVerificationImage}
            alt="request rejected img"
            className="h-63 w-57.25"
          />
          <div className="mt-12 flex text-2xl font-medium text-content">
            Verification Required
          </div>
          <div className="mt-4 text-base font-normal text-grey">
            We will notify you once verification is completed
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center text-sm font-medium leading-4 text-grey">
          Need more help?
          <div className="mb-2 mt-3 font-normal">Talk to our support team</div>
        </div>
        <a
          href={`mailto:${cofeeSupportMail}?subject=${encodeURIComponent(
            cofeeSupportMailSubject
          )}`}
          className="text-base font-medium text-primary underline"
          onClick={onClickContactSupport}
        >
          Contact support
        </a>
      </div>
    </>
  );
};

export default PendingVerification;
