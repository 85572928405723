import { ButtonVariant } from './button';

export enum EmptyScreenType {
  NO_RESULT = 'no_result',
  EMPTY_GROUP = 'empty_group',
  EMPTY_ACTIVE_GROUP = 'empty_active_group',
  EMPTY_INACTIVE_GROUP = 'empty_inactive_group'
}

export interface EmptyScreenProps {
  title?: string;
  description?: string;
  handleClick?: () => void;
  className?: string;
  emptyScreenImage?: string;
  buttonLabel?: string;
  variant?: ButtonVariant;
}
