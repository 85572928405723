import ReactApexChart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

interface SemiCircleGuageChartProps {
  title: string;
  className?: string;
  chartClassName?: string;
  chartValues: {
    primary: { label: string; value: number };
    secondary: { label: string; value: number };
  };
}

const SemiCircleGuageChart = ({
  title,
  className = '',
  chartClassName = '',
  chartValues
}: SemiCircleGuageChartProps) => {
  const series = [
    chartValues.secondary.value !== 0
      ? Math.ceil(
          (chartValues.primary.value / chartValues.secondary.value) * 100
        )
      : 0
  ];
  const options: ApexOptions = {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: '70%'
        },
        offsetY: 20,
        offsetX: -40,
        dataLabels: {
          name: {
            color: '#7E7E7E',
            offsetY: -10,
            fontSize: '14px',
            fontWeight: 'medium'
          },
          value: {
            color: '#1E1B39',
            offsetY: -50,
            fontWeight: 'bold',
            fontSize: '18px'
          }
        }
      }
    },
    colors: ['#1BC49C'],
    labels: ['Paid']
  };

  return (
    <div className={className}>
      <div className="flex items-center gap-2">
        <h1 className="text-lg font-bold text-content">{title}</h1>
        {/* {tooltipInfoText && (
          <InfoIconTooltip
            id="semi-guage-chart"
            tooltipText={tooltipInfoText}
          />
        )} */}
      </div>
      <div className={`flex ${chartClassName}`}>
        <ReactApexChart
          options={options}
          series={series}
          type="radialBar"
          height={400}
          width={440}
        />
        {/* Legend text */}
        <div className="mt-16 flex flex-col gap-8 xl:absolute xl:right-15 2xl:relative 2xl:right-0">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-3">
              <div className="size-3 rounded-full bg-primary" />
              <div className="text-sm font-medium text-grey">
                {chartValues.primary.label}
              </div>
            </div>
            <div className="ml-6 text-lg font-bold text-content">
              {chartValues.primary.value}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-3">
              <div className="size-3 rounded-full bg-grey30" />
              <div className="text-sm font-medium text-grey">
                {chartValues.secondary.label}
              </div>
            </div>
            <div className="ml-6 text-lg font-bold text-content">
              {chartValues.secondary.value}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemiCircleGuageChart;
