export interface MonthInfo {
  name: string;
  shortName: string;
  numeral: string;
}

export const months: Record<number, MonthInfo> = {
  0: { name: 'January', shortName: 'Jan', numeral: '01' },
  1: { name: 'February', shortName: 'Feb', numeral: '02' },
  2: { name: 'March', shortName: 'Mar', numeral: '03' },
  3: { name: 'April', shortName: 'Apr', numeral: '04' },
  4: { name: 'May', shortName: 'May', numeral: '05' },
  5: { name: 'June', shortName: 'Jun', numeral: '06' },
  6: { name: 'July', shortName: 'Jul', numeral: '07' },
  7: { name: 'August', shortName: 'Aug', numeral: '08' },
  8: { name: 'September', shortName: 'Sep', numeral: '09' },
  9: { name: 'October', shortName: 'Oct', numeral: '10' },
  10: { name: 'November', shortName: 'Nov', numeral: '11' },
  11: { name: 'December', shortName: 'Dec', numeral: '12' }
};

export enum TimeZones {
  'Asia/Kolkata' = 330 // +05:30 (5*60 + 30)
}
