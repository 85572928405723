import { TabProps } from 'types/tabs';

const Tabs = ({ tabs, onTabSelect, selectedTab, className }: TabProps) => (
  <div className={`flex border-b-2 border-grey40 ${className || ''}`}>
    {tabs?.map((tab) => (
      <div
        role="presentation"
        className="flex min-w-30 cursor-pointer flex-col md:min-w-180"
        key={tab.id}
        onClick={() => {
          onTabSelect?.(tab);
        }}
      >
        <div
          className={`table-cell truncate px-4 py-3.5 text-center text-base font-medium ${
            selectedTab?.id === tab.id ? 'text-primary' : 'text-content'
          }`}
          key={tab.id}
        >
          {tab.label}
        </div>
        <div
          className={`relative top-0.5 border-b-4 border-primary ${
            selectedTab?.id !== tab.id && 'invisible'
          }`}
        />
      </div>
    ))}
  </div>
);

export default Tabs;
