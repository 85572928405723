import { useEffect, useState } from 'react';

import InfiniteScroll from '../infinite-scroll/InfiniteScroll';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Icon from '../icon/Icon';
import SearchBar from '../searchbar/SearchBar';
import Spinner from '../spinner/Spinner';

import { IdName, MultiSelectorProps, SelectedItemsObj } from './types';

const MultiSelector = (props: MultiSelectorProps) => {
  const {
    onClickSave,
    onClickCancel,
    onClickItem,
    items, // all branches
    subItems, // groups of selected branch
    searchPlaceholder,
    selectorLabel,
    onClose,
    onChangeSearch,
    selectedOptions,
    handleScroll,
    isDisable,
    isFetchingSubItems,
    isLoadingSubItems
  } = props;

  const [lastRowRef, setLastRowRef] = useState<HTMLElement | null>(null);
  const [selectedField, setSelectedField] = useState<IdName>(items[0]); // selected branch
  const [selectedItems, setSelectedItems] =
    useState<SelectedItemsObj>(selectedOptions); // { item_id: [ {id, name}, {id, name} ] }  // all selected groups, grouped as branches

  const onSelectFilterField = (item: IdName) => {
    if (selectedField.id !== item.id) {
      setSelectedField(item);
      onClickItem(item.id);
    }
  };

  const onSelectFilterItem = (item: IdName, isChecked: boolean) => {
    let updatedItems;
    const currentItems = selectedItems[selectedField.id]
      ? [...selectedItems[selectedField.id]]
      : [];
    if (isChecked) {
      updatedItems = [...currentItems, item];
    } else {
      updatedItems = currentItems.filter(
        (oldItem) => oldItem.name !== item.name
      );
    }
    if (updatedItems.length) {
      setSelectedItems({
        ...selectedItems,
        [selectedField.id]: updatedItems
      });
    } else {
      const updatedItem = { ...selectedItems };
      delete updatedItem[selectedField.id];
      setSelectedItems(updatedItem);
    }
  };

  const onSelectAll = (isChecked: boolean) => {
    const updatedItems = { ...selectedItems };
    if (isChecked) {
      updatedItems[selectedField.id] = subItems;
    } else {
      delete updatedItems[selectedField.id];
    }
    setSelectedItems(updatedItems);
  };

  const onSave = () => {
    onClickSave(selectedItems);
  };

  const renderFieldCard = (item: IdName) => {
    const isSelectedField = selectedField?.id === item.id;
    const countOfSelectedItemsPerField = selectedItems[item.id]?.length || 0;
    return (
      <div
        className={`flex cursor-pointer items-center justify-between rounded-lg p-4 text-sm text-content ${
          isSelectedField ? 'bg-selectionPrimary' : ''
        }`}
        role="presentation"
        onClick={() => onSelectFilterField(item)}
      >
        {item.name}
        <div
          className={`flex size-6 min-w-6 items-center justify-center rounded-xl bg-primary text-xs text-white ${
            countOfSelectedItemsPerField ? 'visible' : 'invisible'
          }`}
        >
          {countOfSelectedItemsPerField}
        </div>
      </div>
    );
  };

  const assignLastRowForInfiniteScroll = (
    node: HTMLDivElement | null,
    elementId: string
  ) => {
    if (subItems?.length && subItems[subItems.length - 1].id === elementId) {
      setLastRowRef(node);
    }
  };

  const renderOptionsCard = (item: IdName) => (
    <div
      key={item?.id}
      ref={(node) => assignLastRowForInfiniteScroll(node, item?.id)}
    >
      <Checkbox
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectFilterItem(item, e.target.checked)
        }
        label={item?.name}
        isChecked={
          selectedItems[selectedField.id]?.findIndex(
            (selectedItem) => selectedItem.id === item?.id
          ) >= 0
        }
        labelStyle="!text-content leading-6"
      />
    </div>
  );

  const renderSelectAll = () => {
    const optionsLength = subItems?.length;
    const selectedFieldItemsLength = selectedItems[selectedField.id]?.length;
    return (
      <Checkbox
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectAll(e.target.checked)
        }
        label="Select All"
        isChecked={selectedFieldItemsLength === optionsLength}
        labelStyle="!text-content leading-6"
      />
    );
  };

  const renderNoDataContent = () => (
    <div className="flex h-90 items-center justify-center text-sm text-grey">
      {isLoadingSubItems ? <Spinner /> : 'No groups added yet'}
    </div>
  );

  // to set the first element of list as the selected field on search
  useEffect(() => {
    setSelectedField(items[0]);
  }, [items]);

  return (
    <div className="flex min-w-768 max-w-4xl rounded-lg bg-theme shadow-md">
      <div className="flex w-1/2 flex-col justify-between gap-4 p-4">
        <div>
          <div className="mb-3 text-base font-medium">{selectorLabel}</div>
          <SearchBar
            placeholder={searchPlaceholder}
            onSearch={onChangeSearch}
            searchBarStyle="mb-3"
          />
          <div className="flex h-74 flex-col gap-2 overflow-y-auto">
            {items.length ? (
              items.map((item) => renderFieldCard(item))
            ) : (
              <div className="flex h-70 items-center justify-center text-sm text-grey">
                No branches found
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <Button
            label="Cancel"
            variant="secondary"
            handleClick={onClickCancel}
          />
          <Button
            label="Save"
            handleClick={onSave}
            disabled={!Object.keys(selectedItems).length}
          />
        </div>
      </div>
      <div className="flex w-1/2 flex-col gap-4 border-l border-l-greyStroke p-4">
        <div
          className="ml-auto size-4 cursor-pointer"
          role="presentation"
          onClick={onClose}
        >
          <Icon name="close" size="none" className="size-4" />
        </div>
        <div className="flex w-90 items-center justify-between text-base font-medium leading-6 text-content">
          <div className="inline-block w-65 truncate">
            {selectedField?.name}
          </div>
          {subItems && !!subItems?.length && renderSelectAll()}
        </div>
        <div className="grid h-fit max-h-360 gap-4 overflow-auto">
          {subItems && subItems.length ? (
            <>
              {subItems?.map((item) => renderOptionsCard(item))}
              <InfiniteScroll
                handleScroll={handleScroll}
                isDisable={isDisable}
                isLoading={!!isFetchingSubItems}
                targetElement={lastRowRef}
              />
            </>
          ) : (
            renderNoDataContent()
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelector;
