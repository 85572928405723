import { useLocation, useParams } from 'react-router-dom';

import { Breadcrumb, breadcrumbsMap } from './Breadcrumbs.config';

const useBreadcrumbs: (isDynamic: boolean) => Breadcrumb[] = (
  isDynamic: boolean
) => {
  const routeParams = useParams();
  const { pathname: currentPath } = useLocation();

  const injectParamsToRoute = (route: string, params: any) => {
    const routeParts = route.split('/');
    const injectedRoute = routeParts.map((part) => {
      if (part.startsWith(':')) {
        const requiredParam = part.substring(1, part.length);
        if (params[requiredParam]) return params[requiredParam];
      }
      return part;
    });
    return injectedRoute.join('/');
  };

  const getModifiedPath = (path: string) => {
    if (path.includes(':')) return injectParamsToRoute(path, routeParams);
    return path;
  };

  if (isDynamic) return [];

  let breadcrumb: Breadcrumb[] = [];
  breadcrumbsMap.forEach((value, key) => {
    if (currentPath.startsWith(key)) {
      breadcrumb = value;
    } else if (key.includes(':')) {
      const injectedRoute = injectParamsToRoute(key, routeParams);
      if (currentPath.startsWith(injectedRoute)) {
        breadcrumb = value.map((item) => ({
          name: item.name,
          path: getModifiedPath(item.path)
        }));
      }
    }
  });
  return breadcrumb;
};

export default useBreadcrumbs;
