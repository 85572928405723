import Modal from '@layouts/modal/Modal';
import { Skeleton } from '@mui/material';

import { DropdownOption } from 'types/dropdown';

import theme from '../../../themes/colors';

import Button from '../button/Button';
import PlusNChipList from '../plus-n-chip-list/PlusNChipList';
import MultiSelector from '../multi-selector/MultiSelector';
import { MultiSelectorWithChipsProps } from './types';

const MultiSelectorWithChips = (props: MultiSelectorWithChipsProps) => {
  const {
    emptyStateMessage,
    isSelectorOpen,
    onOpenSelector,
    onCloseSelector,
    selectedItems,
    onRemoveSelected,
    isLoading,
    ...rest
  } = props;

  const allSelectedItems = Object.values(selectedItems).flat();

  const isEmpty = !allSelectedItems.length;

  const chips: DropdownOption[] = allSelectedItems.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.name
  }));

  const removeItemFromSelected = (item: DropdownOption) => {
    onRemoveSelected(item.id as string);
  };

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div className="flex h-12.5 w-full items-center justify-between gap-2 rounded-xl border bg-grey10 p-4 hover:border-grey">
      {isEmpty ? (
        <span className="text-base leading-5 text-subText">
          {emptyStateMessage}
        </span>
      ) : (
        <PlusNChipList
          selectedOptions={chips}
          handleOnRemove={removeItemFromSelected}
          plusMoreClickRequired
          tooltipChipClassName="bg-grey10"
          chipClassName="bg-white"
        />
      )}
      <div>
        <Button
          variant="secondary"
          label="Add"
          rightIconName="plus"
          rightIconStroke={theme.primary}
          rightIconStyle="!ml-1"
          handleClick={onOpenSelector}
          className="h-9"
        />
        {isSelectorOpen && (
          <Modal isOpen onCancel={onCloseSelector}>
            <MultiSelector {...rest} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default MultiSelectorWithChips;
