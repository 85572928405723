import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PAGE_NOT_FOUND } from '@constants/routes';
import { CheckPageAccessProps } from '@types';
import { pageMap } from '@constants/permissions';
import { groupsTabPathPattern } from '@constants/sideMenu';

const useCheckPermission = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { groupId, branchId } = useParams();

  const { rolesAndPermissions } = useSelector((state: any) => state.user);

  const checkPermission = (permission?: string[], accessControlInfo?: any) => {
    const permissions =
      accessControlInfo?.permissions || rolesAndPermissions?.permissions;
    if (!permission) return true;
    if (permissions) return !!permission?.some((p) => permissions?.includes(p));
    return true;
  };

  const checkPageAccess = ({
    permission = pageMap[`${pathname}${search}`],
    accessControlInfo = rolesAndPermissions
  }: CheckPageAccessProps) => {
    // In case of page reloads, rolesAndPermissions fetched directly
    // from the api instead of from the slice
    const isGroupHome = groupsTabPathPattern.test(pathname);

    if (branchId && !isGroupHome) {
      // branchId also available in the groups home page url
      // - which is not be considered in this case
      if (accessControlInfo?.branches === null) return true;
      // if branches is null in api response => all groups are permitted
      const hasBranchAccess = accessControlInfo?.branches?.includes(branchId);
      if (!hasBranchAccess) {
        navigate(PAGE_NOT_FOUND);
        return false;
      }
      return true;
    }

    if (groupId) {
      // if groups is null in api response => all groups are permitted
      if (accessControlInfo?.groups === null) return true;
      const hasGroupAccess = accessControlInfo?.groups?.includes(groupId);
      if (!hasGroupAccess) {
        navigate(PAGE_NOT_FOUND);
        return false;
      }
      return true;
    }

    if (!checkPermission(permission, accessControlInfo)) {
      navigate(PAGE_NOT_FOUND);
      return false;
    }
    return true;
  };

  return { checkPermission, checkPageAccess };
};

export default useCheckPermission;
