import { CSSProperties, ReactNode } from 'react';
import { Tooltip, PlacesType, VariantType } from 'react-tooltip';

export type TooltipType = 'text';

interface CustomTooltipProps {
  id: string; // unique key to identify each tooltip
  type?: TooltipType;
  className?: string;
  place?: PlacesType; // tooltip position
  opacity?: CSSProperties['opacity']; // opacity for tooltip
  variant?: VariantType; // tooltip variant
  clickable?: boolean; // is tooltip clickable inside
  tooltipText?: string; // for type='text'
  showTooltip?: boolean; // to show tooltip onClick
  children?: ReactNode; // for type='children'
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const {
    id,
    type,
    className,
    place = 'top',
    opacity = 1,
    variant = 'dark',
    clickable = false,
    tooltipText,
    showTooltip,
    children
  } = props;
  const renderTooltip = () => {
    const tooltipContent = type === 'text' ? tooltipText : children;

    return (
      <Tooltip
        id={id}
        noArrow
        className={`z-10 !rounded-lg !text-sm !font-normal ${className || ''}`}
        opacity={opacity}
        place={place}
        variant={variant}
        clickable={clickable}
        isOpen={showTooltip && showTooltip}
      >
        {tooltipContent}
      </Tooltip>
    );
  };

  return renderTooltip();
};

export default CustomTooltip;
