import { FC } from 'react';

import { AccordionProps } from './types';
import Icon from '../icon/Icon';

const Accordion: FC<AccordionProps> = ({
  isOpen,
  content,
  title,
  className,
  handleClick
}) => (
  <>
    <div
      role="presentation"
      onClick={handleClick}
      className={`mx-6 flex w-full cursor-pointer justify-between border-t py-6 first:border-t-0 ${
        isOpen ? `pb-2` : ' '
      } ${className}`}
    >
      <div className="pb-2 text-lg font-bold text-content transition-[height] duration-300 ease-out">
        {title}
      </div>

      <div>
        <div className="transition-all duration-150 ease-out">
          {isOpen ? (
            <Icon key="shrink" name="shrink" />
          ) : (
            <Icon key="expand" name="expand" />
          )}
        </div>
      </div>
    </div>
    <div
      className={`overflow-hidden pl-6 pr-10 text-base text-grey transition-[height] duration-500 ease-in-out ${
        isOpen ? 'h-auto pb-6' : 'h-0 p-0'
      }`}
    >
      {isOpen && content}
    </div>
  </>
);

export default Accordion;
