import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as LogoutModalIcon } from '@assets/icons/logout-modal.svg';
import Logo from '@assets/images/logo.svg';
import CoFeeIcon from '@assets/images/cofee-popins.svg';
import { ADMIN_MENU_ITEMS } from '@constants/sideMenu';
import { Avatar, ConfirmationModal, SidebarItem } from '@components';
import { getPhoneNumberWith91 } from '@utils/generic';
import { resetAdminState } from '@features/admin/slices/adminSlice';
import { useLazyLogoutQuery } from '@features/authentication/queries/AuthQuery';
import { clearAllLocalStorageItems, getLocalStorageItem } from '@utils/storage';
import { AUTH } from '@constants/routes';
import {
  setCurrentUser,
  setRolesAndPermissions
} from '@layouts/main-header/userSlice';
import { ApiError } from '@types';
import useNotify from '@hooks/notify';

import theme from '../../../themes/colors';

const AdminSideNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createErrorAlert } = useNotify();

  const [isSidebarOpen, setSideBarOpen] = useState<boolean>(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);

  const { currentUser } = useSelector((state: any) => state.user);
  const userId = getLocalStorageItem('userId');

  const [logout, { isLoading: isLogoutLoading }] = useLazyLogoutQuery();

  const openSidebar = () => {
    setSideBarOpen(true);
  };

  const closeSidebar = () => {
    setSideBarOpen(false);
  };

  const onClickLogout = () => {
    setIsLogoutModalOpen(true);
  };

  const onCancelLogout = () => {
    setIsLogoutModalOpen(false);
  };

  const handleLogoutConfirm = async () => {
    const res = await logout(userId);
    if ('data' in res) {
      dispatch(setCurrentUser({ user: {} }));
      dispatch(setRolesAndPermissions({ permissions: {} }));
      dispatch(resetAdminState());
      clearAllLocalStorageItems();
      navigate(AUTH.LOGIN);
      sessionStorage.removeItem('currentPageOrg');
      sessionStorage.removeItem('currentPageIndividual');
    } else if ('error' in res) {
      const error = res.error as ApiError;
      createErrorAlert(error.data?.message);
    }
  };

  return (
    <div
      onMouseEnter={openSidebar}
      onMouseLeave={closeSidebar}
      className={`group absolute left-0 top-0 z-50 flex h-full flex-col justify-between bg-content shadow-sm transition-all duration-300 ease-in-out ${
        isSidebarOpen ? 'w-64' : 'w-22'
      }`}
    >
      <div>
        <div className="flex items-center gap-2 px-6 pb-6 pt-8">
          <img src={Logo} alt="CoFee logo" className="size-8" />
          {isSidebarOpen && <img src={CoFeeIcon} alt="CoFee" />}
        </div>
        <div className="mx-4 border-t border-t-borderGrey">
          <div className="mt-6 flex flex-col gap-3 py-3">
            {ADMIN_MENU_ITEMS.map((item: any) => {
              const isCurrentRoute = location.pathname.startsWith(item.route);
              return (
                <SidebarItem
                  key={item.id}
                  item={item}
                  isCurrentRoute={isCurrentRoute}
                  isSidebarOpen={isSidebarOpen}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="relative">
        <div
          role="presentation"
          className={`flex cursor-pointer items-center py-3 pl-8 ${
            isSidebarOpen ? 'gap-3' : ''
          } `}
          onClick={onClickLogout}
        >
          <div>
            <LogoutIcon />
          </div>
          {isSidebarOpen && (
            <div className="truncate text-sm font-medium text-white">
              Log Out
            </div>
          )}
        </div>
        <div className="mt-3 flex items-center justify-between gap-3 bg-bgGrey px-4 py-5">
          <div className="flex items-center gap-2">
            <Avatar
              name={currentUser?.name}
              className="size-10 !border-0 text-theme"
              bgColor={theme.blueGrey}
            />
            {isSidebarOpen && (
              <div className="max-w-43 truncate break-words text-sm font-medium text-white">
                {currentUser?.name}
                <div className="flex items-center justify-between text-xs font-normal">
                  <div>{currentUser?.role_name}</div>
                  <div>{getPhoneNumberWith91(currentUser?.mobile)}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLogoutModalOpen && (
        <ConfirmationModal
          isOpen={isLogoutModalOpen}
          ModalIcon={LogoutModalIcon}
          title="Logging Out?"
          message="Are you sure you want to log out?"
          confirmButtonStyle="text-primary"
          handleConfirm={handleLogoutConfirm}
          handleCancel={onCancelLogout}
          className="w-78"
          isConfirmButtonLoading={isLogoutLoading}
        />
      )}
    </div>
  );
};

export default AdminSideNavBar;
