import { SectionTitleProps } from './type';

const SectionTitle = (props: SectionTitleProps) => {
  const { title, description } = props;

  return (
    <div>
      <h2 className="text-xl font-semibold text-content">{title}</h2>
      {description && (
        <div className="mt-2 text-sm text-grey">{description}</div>
      )}
    </div>
  );
};

export default SectionTitle;
