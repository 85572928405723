import PageIndicator from '@components/page-indicator/PageIndicator';
import PageLink from '@components/page-link/PageLink';
import Pagination from '@components/pagination/Pagination';
import { PAGINATION_LIMIT } from '@constants/generic';

interface TablePageIndicatorProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const TablePageIndicator = ({
  currentPage,
  totalPages,
  onPageChange
}: TablePageIndicatorProps) => (
  <div className="mt-2 flex w-full items-center justify-between">
    <PageIndicator
      page={currentPage}
      totalCount={totalPages}
      limit={PAGINATION_LIMIT}
    />
    <Pagination
      page={currentPage}
      setPage={onPageChange}
      totalCount={totalPages}
      limit={PAGINATION_LIMIT}
    />
    <PageLink
      page={currentPage}
      setPage={onPageChange}
      totalCount={totalPages}
      limit={PAGINATION_LIMIT}
    />
  </div>
);

export default TablePageIndicator;
