import { useState } from 'react';

import { allowedImageSize, allowedImageSizeLabel } from '@constants/file';
import { sendMixpanelEvent, Event } from '@core/analytics';

import { ImageUploadProps } from './types';
import Label from '../label/Label';
import Icon from '../icon/Icon';

const ImageUpload = (props: ImageUploadProps) => {
  const {
    label,
    imageSrc,
    showDeleteIcon,
    onClearImage,
    onImageChange,
    acceptedImageFormats = 'image/jpeg, image/png, image/jpg',
    imgClassName,
    subText
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onClear = () => {
    setErrorMessage('');
    if (onClearImage) {
      onClearImage();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPhoto = e?.target?.files?.[0];

    if (selectedPhoto) {
      if (selectedPhoto.size > allowedImageSize) {
        setErrorMessage(
          `Please select a file smaller than ${allowedImageSizeLabel}.`
        );
        sendMixpanelEvent(Event.IMAGE_UPLOAD_ERROR, {
          validation_error: `Image size exceeded ${allowedImageSizeLabel}`,
          image_type: label
        });
        return;
      }
      setErrorMessage('');

      const reader = new FileReader();

      reader.onload = (event) => {
        onImageChange({
          file: selectedPhoto,
          preview: event?.target?.result as string
        });
      };

      reader.readAsDataURL(selectedPhoto);
    }
  };

  const renderImageSelection = () => (
    <div className="flex">
      <input
        type="file"
        id="image-input"
        className="hidden"
        accept={acceptedImageFormats}
        onChange={onChange}
      />
      <label htmlFor="image-input" className="flex cursor-pointer self-center">
        <Icon name="photo-upload" size="none" className="size-22" />
      </label>
    </div>
  );

  return (
    <div className="relative">
      <Label label={label} optionalText={subText} />
      {imageSrc ? (
        <div
          className="relative flex w-fit"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <label htmlFor="image-input" className="cursor-pointer self-center">
            <img
              src={imageSrc}
              className={`
                size-20 rounded-2xl border border-greyStroke
                ${imgClassName} ${isHovered ? 'opacity-50' : 'opacity-100'}`}
              alt="preview"
            />
          </label>
          {showDeleteIcon && (
            <div className="absolute -bottom-2 left-16 flex size-26 cursor-pointer items-center justify-center rounded-full border border-grey bg-grey10 p-1.5">
              <Icon size="large" name="trash" onClick={onClear} />
            </div>
          )}
          {isHovered && (
            <label
              htmlFor="image-input"
              className="absolute bottom-7 left-7 flex cursor-pointer items-center justify-center"
            >
              <Icon name="image-upload" size="24" />
            </label>
          )}
          <input
            type="file"
            id="image-input"
            className="hidden"
            accept={acceptedImageFormats}
            onChange={onChange}
          />
        </div>
      ) : (
        renderImageSelection()
      )}
      {errorMessage && (
        <p className="absolute mt-1 text-xs text-error">{errorMessage}</p>
      )}
    </div>
  );
};

export default ImageUpload;
