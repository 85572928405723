import { useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import { DropdownProps, DropdownOption } from 'types/dropdown';
import useOnClickOutside from '@utils/hooks';

import Button from '../button/Button';
import Icon from '../icon/Icon';
import theme from '../../../themes/colors';

const Dropdown = (props: DropdownProps) => {
  const {
    filterLabel,
    currentFilter,
    filterOptions,
    onSelectFilter,
    className,
    optionsStyle,
    labelStyle,
    isLoading,
    placeholder,
    loadingStyles,
    optionsCount
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const ref = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((isOpen) => !isOpen);
  };

  const onClickFilter = (option: DropdownOption) => {
    if (option.value !== currentFilter && !option.isDisabled) {
      onSelectFilter(option.value);
      toggleDropdown();
    }
  };

  const selectedFilter: DropdownOption | undefined = filterOptions?.find(
    (option) => option.value === currentFilter
  );

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
  });

  if (isLoading)
    return (
      <div className={loadingStyles || 'w-full'}>
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div className={`relative ${className || ''}`} ref={ref}>
      <div className="flex items-center gap-2">
        {filterLabel && (
          <span className="text-base font-medium text-grey">{filterLabel}</span>
        )}
        <Button
          variant="tertiary"
          handleClick={toggleDropdown}
          size="medium"
          label={selectedFilter?.label || placeholder}
          labelCount={optionsCount}
          rightIconName="down-icon-dark"
          className={`min-w-166 justify-between border ${className}`}
          labelStyle={
            placeholder && !selectedFilter?.label
              ? 'text-sm text-grey40'
              : `!font-normal ${labelStyle || ''}`
          }
          rightIconStyle={isDropdownOpen ? 'rotate-180' : ''}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute z-10 mt-1 w-55 origin-top-right rounded-lg border border-grey40 bg-theme px-3 py-1 shadow-sm ${
            optionsStyle || 'right-0'
          }`}
        >
          {filterOptions?.map((option) => (
            <div
              key={option?.id}
              className={`flex  items-center justify-between border-b border-b-grey40 py-2 pr-4 text-sm last:border-b-0 ${
                option.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              role="presentation"
              onClick={() => onClickFilter(option)}
            >
              {option.label}
              {option.value === selectedFilter?.value && (
                <Icon
                  name="check-mark"
                  className="ml-2 !h-4 !min-h-4 !w-4 !min-w-4"
                  stroke={theme.primary}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
