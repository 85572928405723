import { forwardRef } from 'react';

import Icon from '@components/icon/Icon';
import { CheckboxProps } from 'types/checkbox';

import theme from '../../../themes/colors';

import './style.css';

const commonCheckboxStyles = `appearance-none border cursor-pointer rounded-md border-neutral checked:border-primary focus:ring-transparent disabled:cursor-default`;
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      handleChange,
      checkboxStyle,
      labelStyle,
      disabled,
      className,
      htmlKey,
      isChecked,
      ...props
    },
    ref
  ) => (
    <div className={`flex flex-row items-center ${className || ''} `}>
      <label
        className={`relative mr-2 flex items-center ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer '
        } ${checkboxStyle}`}
      >
        <input
          className={`${commonCheckboxStyles} checkbox size-4.5`}
          type="checkbox"
          ref={ref}
          onChange={(e) => {
            handleChange(e);
          }}
          id={htmlKey || (label as string)}
          disabled={disabled}
          checked={isChecked} // New line to set the checked state
          {...props}
        />
        <Icon
          name="checkbox"
          fill={disabled ? theme.grey : theme.primary}
          size="none"
          className={`checkbox-icon absolute size-4.5 rounded-md ${
            isChecked ? 'opacity-100' : 'opacity-0'
          }`} // Adjusted opacity based on isChecked prop
        />
      </label>
      <label
        htmlFor={htmlKey || (label as string)}
        className={`${labelStyle} text-sm font-normal text-grey`}
      >
        {label}
      </label>
    </div>
  )
);

export default Checkbox;
