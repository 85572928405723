import Icon from '@components/icon/Icon';

import { SuccessfullCardProps } from '../types';

const SuccessfullCard = (props: SuccessfullCardProps) => {
  const { doc } = props;

  const { label, fileName } = doc || {};

  return (
    <div className="flex w-full items-center justify-between">
      <div className="w-60 truncate text-base font-medium text-grey">
        {label}
        <div className="truncate text-xs font-normal text-grey">{fileName}</div>
      </div>
      <div className="item-center flex justify-center gap-1 rounded-2xl bg-primaryLite6 px-3 py-1.5 text-xs text-primary">
        <Icon name="filled-check" />
        <div className="pr-1 text-sm text-primary30">Approved</div>
      </div>
    </div>
  );
};

export default SuccessfullCard;
