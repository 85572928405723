import { RouteObject, Navigate } from 'react-router-dom';

import MainLayout from '@layouts/main-layout/MainLayout';
import GroupRoutes from '@features/group-management/routes';
import BranchRoutes from '@features/branch-management/routes';
import TeamManagementRoutes from '@features/team-management/routes';
import {
  ADMIN,
  ADMIN_PAGE_NOT_FOUND,
  AUTH,
  DASHBOARD,
  HOME,
  PAGE_NOT_FOUND
} from '@constants/routes';
import AuthRoutes from '@features/authentication/routes';
import PageNotFoundRoutes, {
  AdminPageNotFoundRoutes
} from '@features/page-not-found/routes';
import { getLocalStorageItem } from '@utils/storage';
import AdminRoutes from '@features/admin/routes';
import AdminLayout from '@layouts/admin-layout/AdminLayout';
import HelpRoutes from '@features/help/routes';
import DashboardRoutes from '@features/dashboard/pages/routes';
import TransactionsRoutes from '@features/transactions/routes';
import SubscriptionPlanRoutes from '@features/subscription/routes';

export const mainRoutes: RouteObject[] = [
  AuthRoutes,
  {
    path: HOME,
    element: (
      <Navigate to={getLocalStorageItem('token') ? DASHBOARD : AUTH.LOGIN} />
    )
  },
  {
    element: <MainLayout />, // Outlet in MainLayout will be replaced by matching element from below
    children: [
      ...GroupRoutes,
      ...BranchRoutes,
      ...TeamManagementRoutes,
      ...PageNotFoundRoutes,
      ...HelpRoutes,
      DashboardRoutes,
      ...TransactionsRoutes,
      SubscriptionPlanRoutes
    ]
  },
  {
    path: '*',
    element: <Navigate to={PAGE_NOT_FOUND} />
  }
];

export const adminRoutes: RouteObject[] = [
  AuthRoutes,
  {
    path: HOME,
    element: (
      <Navigate
        to={getLocalStorageItem('token') ? ADMIN.KYC_DOCUMENTS : AUTH.LOGIN}
      />
    )
  },
  {
    element: <AdminLayout />,
    // Outlet in AdminLayout will be replaced by matching element from below
    children: [...AdminRoutes, ...AdminPageNotFoundRoutes]
  },
  {
    path: '*',
    element: <Navigate to={ADMIN_PAGE_NOT_FOUND} />
  }
];
