import Head from '@layouts/head/Head';
import { OrgStatus, DOCTYPE } from '@constants/generic';

import PendingVerification from '../pending-verification/PendingVerification';
import ReuploadDocument from '../reupload-document/ReUpload';
import RequestRejected from '../request-rejected/RequestRejected';

interface OrgPreApprovedStageProps {
  currentOrgStatus?: OrgStatus;
  documentStatus?: DOCTYPE;
}

const OrgPreApprovedStage = (props: OrgPreApprovedStageProps) => {
  const { currentOrgStatus, documentStatus } = props;

  const renderContent = () => {
    if (
      currentOrgStatus === OrgStatus.PENDING &&
      documentStatus === DOCTYPE.REJECTED
    )
      return <ReuploadDocument />;
    if (
      currentOrgStatus === OrgStatus.PENDING ||
      currentOrgStatus === OrgStatus.VERIFICATION_SUBMITTED
    )
      return (
        <>
          <Head title="Pending Verification" />
          <PendingVerification />
        </>
      );
    if (currentOrgStatus === OrgStatus.REJECTED)
      return (
        <>
          <Head title="Request Rejected" />
          <RequestRejected />
        </>
      );
    return null;
  };

  return (
    <div className="flex h-[calc(100vh-83px)] flex-col items-center justify-center">
      {renderContent()}
    </div>
  );
};

export default OrgPreApprovedStage;
