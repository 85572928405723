import { useEffect, useState } from 'react';

interface DrawerProps {
  isOpen: boolean;
  children: any;
}

const Drawer = ({ isOpen, children }: DrawerProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [shouldRenderChildren, setShouldRenderChildren] =
    useState<boolean>(false);

  useEffect(() => {
    setIsInitialized(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isInitialized) {
      setShouldRenderChildren(true);
    } else {
      setTimeout(() => setShouldRenderChildren(false), 500);
    }
  }, [isInitialized]);

  return (
    <div
      className={`fixed left-0 top-0 z-[1000] ${
        isInitialized || shouldRenderChildren
          ? 'h-screen w-screen bg-black/10'
          : 'size-0'
      }`}
    >
      <div
        className={`fixed right-0 top-0 flex h-screen w-screen justify-end  transition-transform duration-500 ease-in-out ${
          isInitialized ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="h-screen w-fit bg-white shadow-2xl">
          {shouldRenderChildren && children}
        </div>
      </div>
    </div>
  );
};

export default Drawer;
