import { useEffect, useState } from 'react';

import { ProgressBarDataType } from '@features/dashboard/types';

import './MultiProgressBar.css';

interface MultiProgressBarProps {
  data: ProgressBarDataType[];
}

const MultiProgressBar = ({ data }: MultiProgressBarProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const values = data.map((item) => item.value);
  const total = values.reduce((acc, val) => acc + val, 0);
  const percentages = values.map((value) => (value / total) * 100);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    <div className="flex w-full flex-col">
      <div className="flex h-5 w-full gap-0.5 overflow-hidden rounded-full bg-bgBase">
        {percentages.map(
          (percentage, index) =>
            percentage > 0 && (
              <div
                key={`${data[index].id}_progress_bar`}
                className={`h-full rounded-md transition-all duration-500 ease-out ${
                  isMounted ? 'progress-animation' : ''
                }`}
                style={{
                  width: `${percentage}%`,
                  transitionDelay: `${index * 0.5}s`,
                  backgroundColor: data[index].color
                }}
              />
            )
        )}
      </div>
      <div className="mt-12 flex justify-between">
        {data.map((item, index) => (
          <div key={`${item.id}_legend`}>
            <div className="flex items-center gap-2.5 text-grey">
              <div
                className="size-3 rounded-full"
                style={{ backgroundColor: item.color }}
              />
              {item.label}
            </div>
            <div className="ml-6 font-bold">
              {Math.round(percentages[index]) || 0}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiProgressBar;
