import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AvatarWithName from '@components/avatar-with-name/AvatarWithName';
import useNotify from '@hooks/notify';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { ReactComponent as UpgradeToOrgIcon } from '@assets/icons/switch-to-org.svg';
import { ReactComponent as SwitchOrgIcon } from '@assets/icons/switch-organization.svg';
import { AUTH } from '@constants/routes';
import {
  setLocalStorageItem,
  setMultipleLocalStorageItems
} from '@utils/storage';
import { ApiError, MapObj } from '@types';
import { OrgProfileContext } from '@layouts/constants';
import { UserFlow, currentOrgAvatarColor } from '@constants/generic';
import { useCreateOrganizationMutation } from '@features/authentication/queries/AuthQuery';
import { getPhoneNumberWith91 } from '@utils/generic';
import { Event, sendMixpanelEvent } from '@core/analytics';

import { ProfileSettingsProps } from './types';
import theme from '../../../themes/colors';

const ProfileSettings = (props: ProfileSettingsProps) => {
  const { createErrorAlert } = useNotify();
  const [createOrganization] = useCreateOrganizationMutation();

  const {
    orgList,
    onClickUpgradeToOrg,
    onClickSwitchAccount,
    context,
    onClickLogout
  } = props;

  const navigate = useNavigate();

  const { currentUser } = useSelector((state: any) => state.user);

  const onClickAddNewOrg = async () => {
    const response = await createOrganization(null);
    if ('data' in response) {
      setMultipleLocalStorageItems([
        { key: 'newOrgId', value: response.data?.organisation_id },
        { key: 'newBranchId', value: response.data?.branch_id },
        { key: 'userFlow', value: UserFlow.CREATE_ORG }
      ]);
      navigate(AUTH.SELECT_ACCOUNT);
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error.data?.message);
    }
  };

  const onClickCreateAnAccount = () => {
    setLocalStorageItem('userFlow', UserFlow.USER_ONBOARD);
    navigate(AUTH.SELECT_ACCOUNT);
    sendMixpanelEvent(Event.CREATE_ACCOUNT_CLICKED);
  };

  const createNewButtonInfo: MapObj = {
    [OrgProfileContext.CREATE_ORG]: {
      label: 'Add Organization',
      onClick: onClickAddNewOrg,
      Icon: PlusIcon,
      iconStroke: theme.grey,
      labelStyle: 'text-darkGrey'
    },
    [OrgProfileContext.CREATE_ACCOUNT]: {
      label: 'Create an account',
      onClick: onClickCreateAnAccount,
      Icon: PlusIcon,
      iconStroke: theme.grey,
      labelStyle: 'text-darkGrey'
    },
    [OrgProfileContext.UPGRADE_TO_ORG]: {
      label: 'Upgrade to Organization',
      onClick: onClickUpgradeToOrg,
      Icon: UpgradeToOrgIcon,
      labelStyle: 'text-primary'
    },
    [OrgProfileContext.SWITCH_ACCOUNT]: {
      label: 'Switch Account',
      onClick: onClickSwitchAccount,
      Icon: SwitchOrgIcon,
      labelStyle: 'text-darkGrey'
    }
  };

  const renderProfileSettingsItem = (ctx: OrgProfileContext) => {
    const {
      label,
      onClick,
      Icon,
      iconStroke = '',
      labelStyle
    } = createNewButtonInfo[ctx];
    return (
      <div
        className="flex cursor-pointer items-center gap-3 px-6 py-3 hover:bg-grey20"
        role="presentation"
        onClick={onClick}
      >
        <Icon stroke={iconStroke} className="size-6" />
        <div className={`text-sm ${labelStyle}`}>{label}</div>
      </div>
    );
  };

  return (
    <div className="absolute right-0 top-15 z-20 w-68.5 cursor-default overflow-hidden rounded-2xl bg-theme text-sm font-normal shadow-md">
      <div className="border-b border-b-greyStroke px-6 py-3">
        <AvatarWithName
          name={currentUser.name}
          subText={getPhoneNumberWith91(currentUser.mobile)}
          bgColor={currentOrgAvatarColor}
          wrapperClass="gap-4"
          avatarClass="size-10 min-w-10 text-sm text-white"
          nameClass="text-base font-medium break-all mb-1"
        />
      </div>
      {/* Add an Organization / Create an Account / Switch to Organization */}
      {context && renderProfileSettingsItem(context)}
      {orgList?.length > 1 &&
        renderProfileSettingsItem(OrgProfileContext.SWITCH_ACCOUNT)}
      <div
        role="presentation"
        className="flex cursor-pointer items-center gap-4 border-t border-t-greyStroke px-6 py-3 text-base font-medium text-content hover:bg-grey20"
        onClick={onClickLogout}
      >
        <LogoutIcon className="size-6" />
        Log Out
      </div>
    </div>
  );
};

export default ProfileSettings;
