import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import ApprovalGuard from '@layouts/approval-guard/ApprovalGuard';
import MainHeader from '@layouts/main-header/MainHeader';
import SideNavBar from '@layouts/side-nav-bar/SideNavBar';
import MobileLayout from '@layouts/mobile-layout/MobileLayout';
import { Spinner } from '@components';

import AccessGuard from '../access-guard/AccessGuard';

const MainLayout = () => (
  <AccessGuard>
    <div className="z-100 hidden h-screen w-screen overflow-hidden mobile:flex">
      <MobileLayout />
    </div>
    <div className="flex h-screen w-screen overflow-hidden mobile:hidden">
      <div className="size-full pl-21.75">
        <MainHeader />
        <SideNavBar />
        <div className="mt-16 h-screen overflow-y-auto bg-bgBase pb-83">
          <div className="container">
            <Suspense
              fallback={
                <div className="absolute left-1/2 top-1/2 flex size-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <ApprovalGuard>
                <Outlet />
              </ApprovalGuard>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  </AccessGuard>
);
export default MainLayout;
