export const formattedValue = (value: number) => {
  if (value >= 1000000) {
    if (value % 1000000 === 0) return `${(value / 1000000).toFixed(0)}M`;
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    if (value % 1000 === 0) return `${(value / 1000).toFixed(0)}K`;
    return `${(value / 1000).toFixed(1)}K`;
  }
  return `${value}`;
};

export const getSplitAmount = (dateDiff: number) => {
  if (dateDiff <= 15) return dateDiff;

  let closestValue = 0;
  let minDifference = Number.MAX_SAFE_INTEGER;

  for (let i = 7; i <= 15; i += 1) {
    if (dateDiff % i === 0) return i;

    const difference = Math.abs(dateDiff / i - Math.round(dateDiff / i));
    if (difference < minDifference) {
      minDifference = difference;
      closestValue = i;
    }
  }

  return closestValue;
};
