import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { GROUPS } from '@constants/routes';

const Groups = lazy(
  () => import('@features/group-management/pages/groups/Groups')
);

const AddNewGroup = lazy(
  () => import('@features/group-management/pages/add-new-group/AddNewGroup')
);

const GroupInfo = lazy(
  () => import('@features/group-management/pages/group-info/GroupInfo')
);

const AddMembers = lazy(
  () => import('@features/group-management/pages/add-members/AddMembersPage')
);

export const GroupRoutes: RouteObject[] = [
  {
    path: GROUPS.GROUPS,
    element: <Groups />
  },
  {
    path: GROUPS.ADD_NEW_GROUP,
    element: <AddNewGroup />
  },
  {
    path: GROUPS.GROUP_INFO,
    element: <GroupInfo />
  },
  {
    path: GROUPS.ADD_MEMBER,
    element: <AddMembers />
  }
];

export default GroupRoutes;
