// import { useState } from 'react';

import { Avatar, Icon } from '@components';
import { AccountType } from '@constants/generic';

// import EditOrganization from './EditOrganization';
import theme from '../../../themes/colors';

interface ProfileProps {
  isSidebarOpen: boolean;
  currentOrg: any;
  onClickOrganizationEdit?: () => void;
}

const Profile = ({
  isSidebarOpen,
  currentOrg,
  onClickOrganizationEdit
}: ProfileProps) => (
  <div className="relative">
    <div
      className={`mt-3 flex	items-center transition-all  ${
        isSidebarOpen ? 'justify-between' : 'justify-center'
      } gap-3 bg-bgGrey px-4 py-5`}
    >
      <div className="flex items-center gap-2 overflow-hidden">
        <Avatar
          name={currentOrg?.name}
          className="size-10 min-w-10 !border-0 text-theme"
          imageUrl={currentOrg?.image_url}
          bgColor={theme.blueGrey}
        />
        <div
          className={`flex w-45 flex-col justify-center truncate  break-words pt-1 ${
            isSidebarOpen ? '' : 'hidden'
          }`}
        >
          <div className="overflow-hidden text-ellipsis text-sm font-medium text-white">
            {currentOrg?.name}
          </div>
          <div className="overflow-hidden text-ellipsis text-xs font-medium text-subText">
            {currentOrg?.role_name}
          </div>
        </div>
      </div>
      {isSidebarOpen && currentOrg?.type === AccountType.ORGANIZATION && (
        <div
          className="flex size-6 min-w-6 cursor-pointer items-center justify-center rounded-full bg-white"
          role="presentation"
          onClick={() => onClickOrganizationEdit?.()}
        >
          <Icon name="edit" className="size-8 p-0.5" stroke={theme.darkGrey} />
        </div>
      )}
    </div>
  </div>
);

export default Profile;
