// capitalize first letter of a word
// i/p:  abc
// o/p:  Abc

const capitalizeFirstLetter = (inputString: string): string =>
  inputString.charAt(0).toUpperCase() + inputString.slice(1);

// capitalize first letter of each word in an array
// i/p:  ['abc','cdf']
// o/p:  ['Abc','Cdf']

const capitalizeWords = (arr: string[]) =>
  arr.map((word) => {
    const capitalizedFirst = word?.charAt(0)?.toUpperCase();
    const rest = word.slice(1).toLowerCase();
    return capitalizedFirst + rest;
  });

const capitalizeString = (inputString: string): string =>
  inputString.toUpperCase();

// i/p: 1234543121
// o/p: xxxxxx3121
const maskSensitiveData = (data: string) => {
  // Replace all characters except the last four with 'X'
  const lastFour = data.slice(-4);
  const maskedString = data.slice(0, -4).replace(/\d/g, 'x');
  return maskedString + lastFour;
};

export {
  capitalizeFirstLetter,
  capitalizeWords,
  capitalizeString,
  maskSensitiveData
};
