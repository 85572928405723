import {
  EditGroupType,
  GroupDetailsType,
  Member,
  FormDataType,
  MemberListType,
  PaymentCollectionComponentType,
  DateRange,
  AddMemberInput
} from '@types';
import { capitalizeFirstLetter } from '@utils/stringFormat';
import { MonthDay, dayOptions } from '@components/payment-collection/constants';
import {
  formatISODate,
  getOrdinalDateString,
  isFirstDayOfMonth,
  isLastDayOfMonth
} from '@utils/date';
import { MonthArray } from '@constants/groups';
import {
  isInvalidAmount,
  isInvalidGroupName,
  isInvalidMemberName,
  isInvalidPhoneNumber
} from '@utils/validations';
import {
  checkArraysAreSame,
  checkCountryCodeInPhoneNumber
} from '@utils/generic';
import { getLocalStorageItem } from '@utils/storage';

import { PaymentCollectionType } from './constants';

export const getPaymentCollectionComponentType = (
  frequency: string,
  interval_frequency: number
) => {
  let paymentType: PaymentCollectionComponentType =
    PaymentCollectionComponentType.DEFAULT;
  if (frequency === 'monthly' && interval_frequency === 1) {
    paymentType = PaymentCollectionComponentType.MONTHLY;
  } else if (frequency === 'monthly' && interval_frequency > 1) {
    paymentType = PaymentCollectionComponentType.BY_TERM;
  } else if (frequency === 'weekly') {
    paymentType = PaymentCollectionComponentType.WEEKLY;
  }
  return paymentType;
};

export const getDayType = (date: Date) => {
  const day = new Date(date);
  if (isFirstDayOfMonth(day)) return MonthDay.FIRST;
  if (isLastDayOfMonth(day)) return MonthDay.LAST;
  return MonthDay.CUSTOM;
};

const getCustomDate = (date: Date) =>
  getOrdinalDateString(String(new Date(date).getDate()));

const dueDatePlaceholderText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'the first day',
    last: 'the last day',
    custom: 'day'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'First day of the month',
    last: 'Last day of the month',
    custom: 'day of month'
  }
};

export const formatDueDatePlaceholder = (
  type: PaymentCollectionType,
  frequency: number,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `Weekly - ${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;

  const suffix = dueDatePlaceholderText[type][dayType];
  const dateValue =
    dayType === 'custom' ? `${getCustomDate(date)} ${suffix}` : suffix;

  if (type === PaymentCollectionType.BY_TERM) {
    const everyXMonthsOn = `Every ${frequency} months on`;
    return `${everyXMonthsOn} ${dateValue}`;
  }

  return `Monthly - ${dateValue}`;
};

const dueDateDisplayText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'First day of every term',
    last: 'Last day of every term',
    custom: 'of every term'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'First day of month',
    last: 'Last day of month',
    custom: 'day of month'
  }
};

export const formatDueDateDisplayText = (
  type: PaymentCollectionType,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;

  const suffix = dueDateDisplayText[type][dayType];
  const dateValue =
    dayType === 'custom' ? `${getCustomDate(date)} ${suffix}` : suffix;

  return `${dateValue}`;
};

export const getPaymentCollectionType = (
  frequency: string,
  interval_frequency: number
) => {
  let paymentType: PaymentCollectionType = PaymentCollectionType.MONTHLY;
  if (frequency === 'monthly') {
    if (interval_frequency === 1) {
      paymentType = PaymentCollectionType.MONTHLY;
    } else if (interval_frequency > 1) {
      paymentType = PaymentCollectionType.BY_TERM;
    }
  } else if (frequency === 'weekly') {
    paymentType = PaymentCollectionType.WEEKLY;
  }
  return paymentType;
};

const dueDateTooltipText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'the first day of every term',
    last: 'the last day of every term',
    custom: 'of every term'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'the first day of the month',
    last: 'the last day of the month',
    custom: 'of every month'
  }
};

export const formatDueDateTooltip = (
  type: PaymentCollectionType,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `every ${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;
  if (dayType === 'custom') {
    return `the ${getCustomDate(date)} ${dueDateTooltipText[type][dayType]}`;
  }
  return dueDateTooltipText[type][dayType];
};

export const getMonthsTillCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const monthsUntilCurrent = MonthArray.slice(0, currentMonthIndex + 1);
  return monthsUntilCurrent;
};

export const isInvalidMemberList = (
  memberList: MemberListType[],
  maxAmountLimit: string,
  minAmountLimit: string,
  nameMaxLength: string,
  nameMinLength: string
) =>
  memberList.some(
    (member) =>
      (member.amount &&
        isInvalidAmount(member.amount, maxAmountLimit, minAmountLimit)) ||
      isInvalidMemberName(member.name, nameMaxLength, nameMinLength) ||
      !member.primaryNumber ||
      (member.primaryNumber &&
        (String(member.primaryNumber).length !== 12 ||
          isInvalidPhoneNumber(String(member.primaryNumber).slice(2)))) ||
      (member.altNumber &&
        (String(member.altNumber).length !== 12 ||
          isInvalidPhoneNumber(String(member.altNumber).slice(2)) ||
          member.primaryNumber === member.altNumber))
  );
export const invalidCSVErrorMessage =
  'Invalid data found. Update the uploaded file & try again.';

export const getParsedMemberData = (memberList: MemberListType[]) =>
  memberList.map((member) => ({
    name: member.name,
    mobile: member.primaryNumber
      ? checkCountryCodeInPhoneNumber(String(member?.primaryNumber))
      : '',
    amount: Number(member.amount),
    activation_date: member.dueDate
      ? formatISODate({
          date: member.dueDate,
          separator: '-',
          format: 'yyyy-mm-dd'
        })
      : '',
    alternate_mobile: member.altNumber
      ? checkCountryCodeInPhoneNumber(String(member?.altNumber))
      : ''
  }));

export const getCreateGroupPayload = (
  formData: FormDataType,
  imageFilePath: string,
  memberList: MemberListType[]
) => ({
  params: {
    organizationId: getLocalStorageItem('organizationId'),
    branchId: getLocalStorageItem('branchId')
  },
  body: {
    name: formData.groupName,
    image_url: imageFilePath,
    amount: Number(formData.amount),
    activation_date: formatISODate({
      date: formData.activationDate,
      separator: '-',
      format: 'yyyy-mm-dd'
    }),
    interval:
      formData.paymentCollectionType === PaymentCollectionType.WEEKLY
        ? PaymentCollectionType.WEEKLY
        : PaymentCollectionType.MONTHLY,
    interval_frequency:
      formData.paymentCollectionType === PaymentCollectionType.BY_TERM
        ? Number(formData.intervalFrequency)
        : 1,
    members: getParsedMemberData(memberList),
    is_split_payment: formData?.paymentInstallment,
    end_date: formData?.endDate
      ? formatISODate({
          date: formData.endDate,
          separator: '-',
          format: 'yyyy-mm-dd'
        })
      : null,
    is_attendance_tracking_enabled: formData?.isAttandanceTrackingEnabled,
    is_send_attendance_alert_enabled: formData?.isSendAttendanceAlertEnabled,
    working_days: formData?.workingDays
  }
});

// input : date
// output: dayName eg: monday,tuesday...
export const getDayFromDate = (date: Date) => {
  const dayNumber = new Date(date).getDay();
  const day = dayOptions.find((item) => item.id === dayNumber)?.value;
  return day;
};

export const formatDate = (date: string) =>
  formatISODate({ date, separator: '-', format: 'yyyy-mm-dd' });

export const checkHasChangesInEditGroup = (
  groupInfo: EditGroupType,
  groupDetails: GroupDetailsType
) =>
  groupInfo.name !== groupDetails.name ||
  groupInfo.amount !== groupDetails.amount ||
  (groupInfo.groupPhoto.image_url || groupInfo.groupPhoto.preview || '') !==
    (groupDetails.image_url || '') ||
  groupInfo.next_due_at !== formatDate(groupDetails.next_due_at) ||
  groupInfo.frequency !== groupDetails.frequency ||
  groupInfo.interval_frequency !== groupDetails.interval_frequency ||
  groupInfo.paymentInstallment !== groupDetails.is_split_payment ||
  groupInfo.end_date !== groupDetails.end_date ||
  groupInfo.isAttandanceTrackingEnabled !==
    groupDetails.is_attendance_tracking_enabled ||
  groupInfo.isSendAttendanceAlertEnabled !==
    groupDetails.is_send_attendance_alert_enabled ||
  !checkArraysAreSame(
    groupInfo?.workingDays || [],
    groupDetails?.work_days || []
  );

export const checkHasChangesInEditMember = (
  memberDetails: MemberListType,
  member: Member
) =>
  memberDetails.name !== member.name ||
  memberDetails.amount !== member.fee_amount ||
  memberDetails.altNumber !== member.secondary_mobile ||
  memberDetails.dueDate !== formatDate(member.next_due_at);

export const getGroupInfo = (groupDetails: GroupDetailsType) => ({
  groupPhoto: {
    file: '',
    preview: '',
    image_url: groupDetails.image_url || ''
  },
  name: groupDetails.name,
  amount: groupDetails.amount,
  next_due_at: formatDate(groupDetails.next_due_at),
  frequency: groupDetails.frequency,
  interval_frequency: groupDetails.interval_frequency,
  paymentInstallment: groupDetails.is_split_payment,
  end_date: groupDetails.end_date,
  isAttandanceTrackingEnabled: !!groupDetails.is_attendance_tracking_enabled,
  isSendAttendanceAlertEnabled: !!groupDetails.is_send_attendance_alert_enabled,
  workingDays: groupDetails.work_days || []
});

export const getFormattedMemberData = (member: Member) => ({
  id: member.name + member.phone,
  name: member.name || '',
  primaryNumber: member.phone || '',
  altNumber: member.secondary_mobile || '',
  amount: member.fee_amount || '',
  dueDate: member.next_due_at ? formatDate(member.next_due_at) : '',
  is_payment_link_sent: member.is_payment_link_sent || false
});

export const getAnalyticData = (
  groupData: any,
  formData: FormDataType,
  memberList: MemberListType[],
  memberMode: 'File' | 'Manual',
  showBranchInfoInBreadCrumbs: boolean,
  branchName: string
) => ({
  group_name: groupData.name,
  type: formData.paymentCollectionType,
  interval: groupData.interval_frequency,
  payment_collection_date: groupData.activation_date,
  ...(formData.paymentCollectionType === PaymentCollectionType.WEEKLY && {
    payment_collection_day: getDayFromDate(new Date(groupData.activation_date))
  }),
  amount: groupData.amount,
  is_split_payment: groupData.is_split_payment,
  ...(getParsedMemberData(memberList).length > 0 && {
    add_member_mode: memberMode
  }),
  ...(showBranchInfoInBreadCrumbs && {
    branch_name: branchName
  }),
  ...(groupData?.end_date && {
    end_date: groupData.end_date
  }),
  members: getParsedMemberData(memberList)
});

export const formatMonthForAttendance = (date: string) =>
  formatISODate({ date, separator: ' ', format: 'MMMM YYYY' });

export const formatDateForAttendance = (date: string) =>
  formatISODate({ date, separator: ' ', format: 'DD MMMM YYYY' });

export const formatDateRange = (dateRange: DateRange) => {
  // adjust the time zone offset between local time zone and UTC
  const startOfMonthWithOffset = new Date(
    dateRange.startDate.getTime() -
      dateRange.startDate.getTimezoneOffset() * 60000
  ).toISOString();
  const endOfMonthWithOffset = new Date(
    dateRange.endDate.getTime() - dateRange.endDate.getTimezoneOffset() * 60000
  ).toISOString();
  return { startDate: startOfMonthWithOffset, endDate: endOfMonthWithOffset };
};

export const isAddNewGroupSaveDisabled = (
  formData: FormDataType,
  groupMemberMaxAmount: any,
  groupMemberMinAmount: any
) =>
  isInvalidGroupName(formData.groupName) ||
  !formData.activationDate ||
  isInvalidAmount(
    formData.amount,
    groupMemberMaxAmount,
    groupMemberMinAmount
  ) ||
  (formData?.isAttandanceTrackingEnabled &&
    formData?.workingDays?.length === 0);

export const parseDateString = (dateString: string) => {
  // Check if date is in the format dd-mm-yyyy
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(Number(year), Number(month) - 1, Number(day));
  }
  // Check if date is in the format yyyy-mm-dd
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  return null;
};

export const getValidationErrorsOnMemberDetails = (
  dataHeaders: string[],
  memberDetails: AddMemberInput[],
  maxAmount: number,
  minAmount: number
): string[] => {
  // Validate Headers
  const defaultHeaders = [
    'name',
    'primaryNumber',
    'altNumber',
    'amount',
    'dueDate'
  ];
  if (
    checkArraysAreSame(
      dataHeaders.filter((header) => header !== '' && !header.startsWith('_')),
      defaultHeaders
    ) === false
  ) {
    return ['Invalid Headers'];
  }

  const errors: string[] = [];
  const primaryNumbers: string[] = [];
  const altNumbers: string[] = [];

  memberDetails.forEach((member: AddMemberInput, index: number) => {
    const { name, primaryNumber, altNumber, amount, dueDate } = member;

    // Validate name
    if (!name || name.length < 3) errors.push(`Row ${index + 1}: Invalid name`);

    //  validate primary number
    if (
      !primaryNumber ||
      (primaryNumber &&
        (String(primaryNumber).length !== 12 ||
          isInvalidPhoneNumber(String(primaryNumber).slice(2))))
    )
      errors.push(
        `Row ${index + 1}: Primary Number should be in the format 91XXXXXXXXXX`
      );

    // check if primary number is duplicate
    if (primaryNumbers.includes(primaryNumber || ''))
      errors.push(`Row ${index + 1}: Duplicate Primary Number`);

    // validate alternate number
    if (
      altNumber &&
      (String(altNumber).length !== 12 ||
        isInvalidPhoneNumber(String(altNumber).slice(2)))
    )
      errors.push(
        `Row ${
          index + 1
        }: Alternative Number should be in the format 91XXXXXXXXXX`
      );

    // check if alternate number is duplicate
    if (altNumbers.includes(altNumber || ''))
      errors.push(`Row ${index + 1}: Duplicate Alternative Number`);

    // check primary and secondary numbers are same
    if (altNumber && primaryNumber === altNumber)
      errors.push(
        `Row ${index + 1}: Primary and Alternative Number should not be same`
      );

    // check amount is valid
    if (amount && Number(amount).toLocaleString() === 'NaN')
      errors.push(`Row ${index + 1}: Invalid amount`);

    // check amount value
    if (amount && (Number(amount) < minAmount || Number(amount) > maxAmount))
      errors.push(
        `Row ${
          index + 1
        }: Amount should be between ${minAmount} and ${maxAmount}`
      );

    // Validate due date
    if (dueDate) {
      const date = parseDateString(dueDate);
      if (!date || date.toString() === 'Invalid Date')
        errors.push(
          `Row ${
            index + 1
          }: Date should be in the format dd-mm-yyyy or yyyy-mm-dd`
        );
      if (date && date.getTime() < new Date().setHours(0, 0, 0, 0))
        errors.push(
          `Row ${index + 1}: Due date should be greater than or equal to today`
        );
    }

    // Add Primary Number and AltNumber to array
    if (member.primaryNumber) primaryNumbers.push(member.primaryNumber);
    if (member.altNumber) altNumbers.push(member.altNumber);
  });

  return errors;
};
