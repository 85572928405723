import { useNavigate } from 'react-router-dom';

interface BackWithCustomTextProps {
  statement?: string;
  clickLabel?: string;
}

const BackWithCustomText = (props: BackWithCustomTextProps) => {
  const {
    statement = 'Need to return to the previous page?',
    clickLabel = 'Click here.'
  } = props;

  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);

  return (
    <div className="mt-2 text-sm text-grey">
      {statement}{' '}
      <span
        role="presentation"
        className="cursor-pointer text-primary"
        onClick={navigateBack}
      >
        {clickLabel}
      </span>
    </div>
  );
};

export default BackWithCustomText;
