export enum MonthDay {
  FIRST = 'first',
  LAST = 'last',
  CUSTOM = 'custom'
}

export const monthCardOptions = [
  {
    key: MonthDay.FIRST,
    value: 'First day of the month'
  },
  {
    key: MonthDay.LAST,
    value: 'Last day of the month'
  },
  {
    key: MonthDay.CUSTOM,
    value: 'Custom day of the month'
  }
];

export enum Weekdays {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday'
}

export const dayOptions = [
  { id: 0, label: 'Sun', value: Weekdays.SUNDAY },
  { id: 1, label: 'Mon', value: Weekdays.MONDAY },
  { id: 2, label: 'Tue', value: Weekdays.TUESDAY },
  { id: 3, label: 'Wed', value: Weekdays.WEDNESDAY },
  { id: 4, label: 'Thu', value: Weekdays.THURSDAY },
  { id: 5, label: 'Fri', value: Weekdays.FRIDAY },
  { id: 6, label: 'Sat', value: Weekdays.SATURDAY }
];

export const weekList = [
  { key: Weekdays.SUNDAY, label: 'S' },
  { key: Weekdays.MONDAY, label: 'M' },
  { key: Weekdays.TUESDAY, label: 'T' },
  { key: Weekdays.WEDNESDAY, label: 'W' },
  { key: Weekdays.THURSDAY, label: 'T' },
  { key: Weekdays.FRIDAY, label: 'F' },
  { key: Weekdays.SATURDAY, label: 'S' }
];
