import { useEffect, useState } from 'react';

import RadioGroup from '@components/radio-button/RadioGroup';
import {
  getLastDayOfMonthUTC,
  getNextMonthDate,
  getCurrentMonthDate,
  isFutureOrCurrentDay,
  isFutureDay
} from '@utils/date';
import { CardProps } from 'types/payment-options';
import { PaymentCollectionType } from '@features/group-management/constants';
import { getDayType } from '@features/group-management/helper';

import { MonthDay, monthCardOptions } from '../constants';

const MonthCard = ({
  handleActivationDate,
  handleIntervalFrequency,
  selectedDate,
  type
}: CardProps) => {
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedType, setSelectedType] = useState('');

  const handleDateSelect = (item: string) => {
    let nextDueDate;
    if (isFutureOrCurrentDay(item)) {
      nextDueDate = getCurrentMonthDate(item);
    } else {
      nextDueDate = getNextMonthDate(item);
    }
    const isFutureDate = isFutureDay(item);
    handleActivationDate(new Date(nextDueDate), isFutureDate);
  };

  const handleDayClick = (item: string) => {
    setSelectedType(MonthDay.CUSTOM);
    setSelectedDay(item);
    handleDateSelect(item);
  };

  const handleTypeSelect = (item: string) => {
    setSelectedType(item);
    if (item === MonthDay.CUSTOM) {
      setSelectedDay('1');
      handleDateSelect('1');
    } else setSelectedDay('');
    if (item === MonthDay.LAST) {
      const lastDay = getLastDayOfMonthUTC();
      handleActivationDate(lastDay);
    }
    if (item === MonthDay.FIRST) {
      handleDateSelect('1');
    }
  };

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.MONTHLY) {
      setSelectedType(MonthDay.FIRST);
      handleDateSelect('1');
      handleIntervalFrequency('');
    } else if (selectedDate && type === PaymentCollectionType.MONTHLY) {
      const dayType = getDayType(selectedDate);
      setSelectedType(dayType);
      if (dayType === MonthDay.CUSTOM) {
        const day = new Date(selectedDate).getDate();
        setSelectedDay(String(day));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <>
      <RadioGroup
        options={monthCardOptions}
        handleChange={handleTypeSelect}
        orientation="vertical"
        selectedValue={selectedType}
      />
      <div className="grid grid-cols-7 gap-2 pb-6 pt-1">
        {Array.from({ length: 28 }, (_, i) => (
          <div
            key={i}
            className={`flex size-8 cursor-pointer items-center justify-center rounded-full text-content ${
              Number(selectedDay) === i + 1 ? 'bg-primary text-theme' : ''
            }`}
            role="presentation"
            onClick={() => handleDayClick(String(i + 1))}
          >
            {i + 1}
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthCard;
