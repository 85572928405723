import Pagination from '@mui/material/Pagination';

import { CustomPaginationProps } from 'types/pagination';

import theme from '../../../themes/colors';

const CustomPagination = ({
  totalCount,
  limit,
  page,
  setPage
}: CustomPaginationProps) => {
  const pageCount = Math.ceil(totalCount / limit);

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  return (
    <Pagination
      color="standard"
      shape="rounded"
      className="text-grey"
      sx={{
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: theme.bgBase,
          color: theme.content,
          border: `1px solid ${theme.primary}`
        }
      }}
      count={pageCount}
      page={page}
      hideNextButton={page === pageCount}
      hidePrevButton={page === 1}
      onChange={handlePageChange}
    />
  );
};

export default CustomPagination;
