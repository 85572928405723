import { Skeleton } from '@mui/material';
import clsx from 'clsx';

import { SwitchInterface } from '@types';

const Switch: React.FC<SwitchInterface> = ({
  handleClick,
  value = true,
  disabled = false,
  className,
  label,
  loading,
  hasAccess = true,
  labelClassName,
  ...props
}) => {
  const toggleSwitch = () => {
    if (!disabled && !loading) {
      handleClick(!value);
    }
  };

  const containerClassNames = 'flex h-6 items-center justify-center';
  const buttonClassNames = clsx(
    'flex h-4.5 w-8 cursor-pointer items-center rounded-3xl p-1',
    disabled && 'cursor-not-allowed opacity-50'
  );

  const buttonVariantStyles = value
    ? 'bg-primary20 border border-primary'
    : 'border border-grey80 bg-white';

  const switchVariantStyles = value
    ? 'translate-x-2.5 bg-primary'
    : '-translate-x-1 bg-white border border-grey80';

  const switchClassNames = 'h-4 w-4 rounded-3xl duration-300 ease-in-out';

  return loading ? (
    <Skeleton animation="wave" width={86} />
  ) : (
    <div className={clsx(containerClassNames, className)}>
      <span
        className={clsx(
          `mr-2 text-base ${labelClassName}`,
          value ? 'text-primary' : 'text-grey80'
        )}
      >
        {label}
      </span>
      {hasAccess && (
        <button
          type="button"
          className={clsx(buttonClassNames, buttonVariantStyles)}
          onClick={toggleSwitch}
          {...props}
        >
          <div className={clsx(switchClassNames, switchVariantStyles)} />
        </button>
      )}
    </div>
  );
};

export default Switch;
