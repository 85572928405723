import { useEffect, useState } from 'react';

import { CardProps, DayOptionsType } from 'types/payment-options';
import {
  getDateOfNextWeek,
  getDateOfCurrentWeekDay,
  isCurrentOrFutureDayOfWeek,
  isFutureDayOfWeek
} from '@utils/date';
import { PaymentCollectionType } from '@features/group-management/constants';

import { dayOptions } from '../constants';

const WeekCard = ({
  handleActivationDate,
  handleIntervalFrequency,
  selectedDate,
  type
}: CardProps) => {
  const [selectedDay, setSelectedDay] = useState<DayOptionsType>();

  const handleDateSelect = (id: number) => {
    let nextDueDate;
    if (isCurrentOrFutureDayOfWeek(id)) {
      nextDueDate = getDateOfCurrentWeekDay(id);
    } else {
      nextDueDate = getDateOfNextWeek(id);
    }
    const isFutureDate = isFutureDayOfWeek(id);
    handleActivationDate(nextDueDate, isFutureDate);
  };

  const handleDayClick = (item: DayOptionsType) => {
    setSelectedDay(item);
    handleDateSelect(item.id);
  };

  useEffect(() => {
    if (!selectedDate) {
      handleDateSelect(dayOptions[0].id);
      handleIntervalFrequency('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.WEEKLY)
      setSelectedDay(dayOptions[0]);
    else if (selectedDate && type === PaymentCollectionType.WEEKLY)
      setSelectedDay(dayOptions[new Date(selectedDate).getDay()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div className="grid grid-cols-3 gap-4 pb-6">
      {dayOptions.map((item) => (
        <div
          key={item.id}
          className={`flex h-10 w-20 cursor-pointer items-center justify-center rounded-lg bg-grey10 text-grey ${
            selectedDay?.id === item.id
              ? 'border border-primary text-primary'
              : ''
          }`}
          role="presentation"
          onClick={() => handleDayClick(item)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default WeekCard;
