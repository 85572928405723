import { useEffect, useRef, useState } from 'react';

import { IdLabelValue } from '@types';

import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Icon from '../icon/Icon';

import {
  FieldOption,
  FieldOptionObj,
  MultiFieldFilterProps,
  SelectedFilterValues
} from './types';

const getInitialField = (fieldOptions: FieldOptionObj) => {
  const allFields = Object.keys(fieldOptions);
  return allFields[0];
};

const MultiFieldFilter = (props: MultiFieldFilterProps) => {
  const { fieldOptions, handleApplyFilter, buttonClass, selectedFilters } =
    props;

  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<string>(
    getInitialField(fieldOptions)
  );

  const initalState =
    (Object.keys(selectedFilters).length && selectedFilters) || {};

  const [selectedItems, setSelectedItems] =
    useState<SelectedFilterValues>(initalState); // { status: ['pending', 'accepted'], role: ['admin'] }

  const countRef = useRef(0);

  useEffect(() => {
    countRef.current = Object.values(selectedFilters).flat().length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const onSelectFilterField = (field: string) => {
    setSelectedField(field);
  };

  const onCloseFilter = () => {
    setOpen(false);
    setSelectedField(getInitialField(fieldOptions));
    setSelectedItems({});
  };

  const onOpenFilter = () => {
    setOpen(true);
    setSelectedItems(initalState);
  };

  const toggleFilter = () => {
    if (isOpen) {
      onCloseFilter();
    } else {
      onOpenFilter();
    }
  };

  const onClearAllFilters = () => {
    handleApplyFilter({});
    toggleFilter();
  };

  const onApplyFilters = () => {
    handleApplyFilter(selectedItems);
    toggleFilter();
  };

  const onSelectFilterItem = (item: IdLabelValue, isChecked: boolean) => {
    let updatedItems;
    const currentItems = selectedItems[selectedField]
      ? [...selectedItems[selectedField]]
      : [];
    if (isChecked) {
      updatedItems = [...currentItems, item.value];
    } else {
      updatedItems = currentItems.filter((oldItem) => oldItem !== item.value);
    }
    setSelectedItems({
      ...selectedItems,
      [selectedField]: updatedItems
    });
  };

  const onSelectAll = (isChecked: boolean) => {
    let updatedItems: string[];
    if (isChecked) {
      const allOptions = fieldOptions[selectedField].options;
      updatedItems = allOptions.map((option) => option.value);
    } else {
      updatedItems = [];
    }
    setSelectedItems({
      ...selectedItems,
      [selectedField]: updatedItems
    });
  };

  const renderFieldCard = (item: FieldOption) => {
    const isSelectedField = selectedField === item.field;
    const countOfSelectedItemsPerField = selectedItems[item.field]?.length || 0;

    return (
      <div
        className={`flex cursor-pointer items-center justify-between rounded-lg p-4 text-sm text-content ${
          isSelectedField ? 'bg-selectionPrimary' : ''
        }`}
        role="presentation"
        onClick={() => onSelectFilterField(item.field)}
        key={item.id}
      >
        {item.label}
        <div
          className={`flex size-6 items-center justify-center rounded-xl bg-primary text-sm text-white ${
            countOfSelectedItemsPerField ? 'visible' : 'invisible'
          }`}
        >
          {countOfSelectedItemsPerField}
        </div>
      </div>
    );
  };

  const renderOptionsCard = (item: IdLabelValue) => (
    <div key={item.id}>
      <Checkbox
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectFilterItem(item, e.target.checked)
        }
        label={item.label}
        isChecked={selectedItems[selectedField]?.includes(item.value)}
        labelStyle="!text-content leading-6"
      />
    </div>
  );

  const renderSelectAll = () => {
    const optionsLength = fieldOptions[selectedField].options?.length;
    const selectedFieldItemsLength = selectedItems[selectedField]?.length;
    return (
      <Checkbox
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectAll(e.target.checked)
        }
        label="Select All"
        isChecked={selectedFieldItemsLength === optionsLength}
        labelStyle="!text-content leading-6"
        checkboxStyle="mr-3"
      />
    );
  };

  const renderCountIndicator = () => {
    const totalSelectedItemsCount = countRef.current;
    return (
      <div
        className={`absolute -right-1.5 -top-1.5 flex size-5 items-center justify-center rounded-xl bg-primary text-xs text-theme ${
          totalSelectedItemsCount ? 'visible' : 'invisible'
        }`}
      >
        {totalSelectedItemsCount}
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="relative">
        <Button
          variant="tertiary"
          handleClick={toggleFilter}
          size="medium"
          label="Filter"
          className={`ml-auto w-24 justify-between ${buttonClass}`}
          leftIconName="filter"
        />
        {renderCountIndicator()}
      </div>
      {isOpen && (
        <div className="absolute right-0 z-10 mt-1 flex min-w-623 origin-top-right rounded-lg bg-theme shadow-multiFieldFilterShadow">
          <div className="flex w-1/2 flex-col justify-between gap-3 p-3">
            <div className="flex flex-col gap-3">
              <div className="text-base font-medium">Filters</div>
              {Object.values(fieldOptions).map((item) => renderFieldCard(item))}
            </div>
            <div className="grid grid-cols-2 gap-3">
              <Button
                label="Clear"
                variant="secondary"
                handleClick={onClearAllFilters}
              />
              <Button label="Save" handleClick={onApplyFilters} />
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-4 border-l border-l-greyStroke p-3">
            <div
              className="ml-auto size-4 cursor-pointer"
              role="presentation"
              onClick={toggleFilter}
            >
              <Icon name="close" size="none" className="size-4" />
            </div>
            <div className="flex items-center justify-between text-base font-medium leading-6 text-content">
              {fieldOptions[selectedField].label}
              {renderSelectAll()}
            </div>
            <div className="grid gap-3">
              {fieldOptions[selectedField].options.map((item) =>
                renderOptionsCard(item)
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiFieldFilter;
