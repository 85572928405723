/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AUTH } from '@constants/routes';
import {
  useGetUserDetailsQuery,
  useLazyGetRolesAndPermissionsQuery
} from '@features/authentication/queries/AuthQuery';
import useCheckPermission from '@hooks/checkPermission';
import {
  setCurrentUser,
  setRolesAndPermissions
} from '@layouts/main-header/userSlice';
import { getLocalStorageItem } from '@utils/storage';
import { useLazyGetAdminRolesAndPermissionsQuery } from '@features/admin/queries/AdminQuery';
import { PageNames } from '@core/analytics/events';
import { trackPageView } from '@core/analytics';
import { UserTypes } from '@constants/role';

const AccessGuard = ({ children }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { checkPageAccess } = useCheckPermission();

  const orgId = getLocalStorageItem('organizationId');
  const token = getLocalStorageItem('token');
  const userId = getLocalStorageItem('userId');
  const userType = getLocalStorageItem('userType');

  const [
    getRolesAndPermissions,
    {
      data: rolesAndPermissions,
      isFetching: isPermissionsFetching,
      isSuccess: isPermissionsSuccess
    }
  ] = useLazyGetRolesAndPermissionsQuery();

  const [
    getAdminRolesAndPermissions,
    {
      data: adminRolesAndPermissions,
      isFetching: isAdminPermissionsFetching,
      isSuccess: isAdminPermissionsSuccess
    }
  ] = useLazyGetAdminRolesAndPermissionsQuery();

  const {
    data: currentUser,
    isFetching: isCurrentUserFetching,
    isSuccess: isCurrentUserSuccess
  } = useGetUserDetailsQuery(
    {
      userId,
      query: 'include_payment_stats=true&include_group_stats=true'
    },
    { skip: !userId, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (!isCurrentUserFetching && isCurrentUserSuccess)
      dispatch(setCurrentUser({ user: currentUser }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (
      (!isPermissionsFetching && isPermissionsSuccess) ||
      (!isAdminPermissionsFetching && isAdminPermissionsSuccess)
    ) {
      dispatch(
        setRolesAndPermissions({
          permissions:
            userType === UserTypes.ADMIN
              ? adminRolesAndPermissions
              : rolesAndPermissions
        })
      );
    }
  }, [
    rolesAndPermissions,
    adminRolesAndPermissions,
    isAdminPermissionsFetching,
    isPermissionsFetching
  ]);

  useEffect(() => {
    const accessControlInfo =
      userType === UserTypes.ADMIN
        ? adminRolesAndPermissions
        : rolesAndPermissions;
    if (!token || !accessControlInfo) return;
    checkPageAccess({ accessControlInfo });
  }, [pathname, rolesAndPermissions, adminRolesAndPermissions]);

  useEffect(() => {
    if (!token) return;
    if (userType === UserTypes.ADMIN) getAdminRolesAndPermissions(null);
    else getRolesAndPermissions(orgId);
  }, [orgId]);

  useEffect(() => {
    // If page name is present in PageNames object, track the page view,
    // other pages will be tracked in their respective pages
    if (PageNames[pathname]) {
      trackPageView(PageNames[pathname]);
    }
  }, [pathname]);

  useEffect(() => {
    if (!token) navigate(AUTH.LOGIN);
  }, []);

  return children;
};

export default AccessGuard;
