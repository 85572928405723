import { IconButton } from '@components/button/Button';
import Tooltip from '@components/tooltip/Tooltip';
import CopyToClipboard from '@components/copy-to-clipboard/CopyToClipboard';

import theme from '../../../themes/colors';

interface ActionButtonProps {
  id: string;
  handleEdit?: (event: React.MouseEvent, id: string) => void;
  handleDelete?: (event: React.MouseEvent, id: string) => void;
  handleView?: (event: React.MouseEvent, id: string) => void;
  handleDownload?: (event: React.MouseEvent, id: string) => void;
  handleMarkAsPaid?: (event: React.MouseEvent, id: string) => void;
  handleDisable?: (event: React.MouseEvent, id: string) => void;
  handleSendReminder?: (event: React.MouseEvent, id: string) => void;
  deleteButtonStyle?: string;
  isEditDisabled?: boolean;
  textToCopy?: string;
  isMarkAsPaidDisabled?: boolean;
  isDisableIconDisabled?: boolean;
  isSendReminderDisabled?: boolean;
  onCopy?: () => void;
}

const ActionButton = ({
  id,
  handleEdit,
  handleDelete,
  handleView,
  handleDownload,
  handleMarkAsPaid,
  handleDisable,
  handleSendReminder,
  deleteButtonStyle,
  isEditDisabled = false,
  textToCopy,
  isMarkAsPaidDisabled,
  isDisableIconDisabled,
  isSendReminderDisabled,
  onCopy
}: ActionButtonProps) => (
  <div className="flex gap-4">
    {handleEdit && (
      <IconButton
        iconName="edit"
        stroke={theme.grey}
        variant="transparent"
        className={`!p-0 ${
          isEditDisabled ? 'cursor-not-allowed opacity-35' : ''
        }`}
        iconSize="large"
        size="none"
        handleClick={(event) => handleEdit(event, id)}
        disabled={isEditDisabled}
      />
    )}
    {handleDelete && (
      <IconButton
        iconName="trash"
        variant="transparent"
        size="none"
        className={`!p-0 ${deleteButtonStyle || ''}`}
        handleClick={(event) => handleDelete(event, id)}
      />
    )}
    {handleView && (
      <>
        <IconButton
          iconName="eye"
          variant="transparent"
          size="none"
          className="!p-0"
          handleClick={(event) => handleView(event, id)}
          data-tooltip-id={`view-icon-${id}`}
        />
        <Tooltip id={`view-icon-${id}`} type="text" tooltipText="View" />
      </>
    )}
    {handleDownload && (
      <>
        <IconButton
          iconName="download"
          stroke={theme.grey}
          variant="transparent"
          size="none"
          className="!p-0"
          handleClick={(event) => handleDownload(event, id)}
          data-tooltip-id={`download-icon-${id}`}
        />
        <Tooltip
          id={`download-icon-${id}`}
          type="text"
          tooltipText="Download"
        />
      </>
    )}
    {handleMarkAsPaid && (
      <>
        <IconButton
          iconName="check-mark"
          variant="transparent"
          size="none"
          stroke={isMarkAsPaidDisabled ? theme.grey40 : theme.primary}
          className="!p-0"
          handleClick={(event) => handleMarkAsPaid(event, id)}
          data-tooltip-id={`mark-as-paid-icon-${id}`}
          disabled={isMarkAsPaidDisabled}
        />
        {!isMarkAsPaidDisabled && (
          <Tooltip
            id={`mark-as-paid-icon-${id}`}
            type="text"
            tooltipText="Mark as paid"
          />
        )}
      </>
    )}
    {handleDisable && (
      <>
        <IconButton
          iconName="red-cross"
          variant="transparent"
          size="none"
          className="!p-0"
          handleClick={(event) => handleDisable(event, id)}
          data-tooltip-id={`disable-icon-${id}`}
          disabled={isDisableIconDisabled}
        />
        {!isDisableIconDisabled && (
          <Tooltip
            id={`disable-icon-${id}`}
            type="text"
            tooltipText="Disable"
          />
        )}
      </>
    )}
    {handleSendReminder && (
      <>
        <IconButton
          iconName="bell"
          variant="transparent"
          size="none"
          stroke={isSendReminderDisabled ? theme.grey40 : theme.grey}
          className="!p-0"
          handleClick={(event) => handleSendReminder(event, id)}
          data-tooltip-id={`send-reminder-icon-${id}`}
          disabled={isSendReminderDisabled}
        />
        {!isSendReminderDisabled && (
          <Tooltip
            id={`send-reminder-icon-${id}`}
            type="text"
            tooltipText="Send Reminder"
          />
        )}
      </>
    )}
    {textToCopy && (
      <CopyToClipboard
        textToCopy={textToCopy || ''}
        textId={id}
        className="!p-0"
        iconColor={`${isSendReminderDisabled ? theme.grey40 : 'text-grey'}`}
        isDisabled={isSendReminderDisabled}
        onCopy={onCopy}
      />
    )}
  </div>
);

export default ActionButton;
