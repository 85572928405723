import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { renderToString } from 'react-dom/server';

import { formattedValue, getSplitAmount } from '@utils/charts';
import { formatISODate } from '@utils/date';

interface LineChartProps {
  title?: string;
  className?: string;
  xAxisValues: number[];
  seriesData: {
    name: string;
    data: number[][];
  }[];
  branchName?: string;
  startDate: string;
  endDate: string;
}

const LineChart = ({
  title = '',
  className = '',
  xAxisValues,
  seriesData,
  branchName,
  startDate,
  endDate
}: LineChartProps) => {
  const formatDate = (dateString: string) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const getTickAmount = () => {
    const days =
      (formatDate(endDate).getTime() - formatDate(startDate).getTime()) /
      (1000 * 60 * 60 * 24);

    return getSplitAmount(days);
  };

  const options: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    // Todo: update with actual data
    xaxis: {
      type: 'datetime',
      tickAmount: getTickAmount(),
      min: formatDate(startDate).getTime(),
      max: formatDate(endDate).getTime(),
      labels: {
        formatter: (val, timestamp = 0) =>
          `${formatISODate({
            date: new Date(timestamp),
            format: 'DD MMM'
          })}`
      }
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: (value) => formattedValue(value as number)
      }
    },
    grid: {
      borderColor: '#e7e7e7',
      strokeDashArray: 5
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false
      },
      itemMargin: {
        horizontal: 8
      }
    },
    colors: branchName
      ? ['#17AC88', '#E98E39']
      : ['#DAA3F4', '#74ABFF', '#F1CD49', '#59C3CF', '#E98E39'],
    stroke: {
      curve: 'straight'
    },

    tooltip: {
      custom: ({ series, dataPointIndex, seriesIndex, w }) => {
        const seriesName = branchName || w.globals.seriesNames[seriesIndex];
        const seriesColor = w.globals.colors[seriesIndex];
        const primaryValue = series[seriesIndex][dataPointIndex];
        const secondaryValue = w.globals.seriesZ[seriesIndex][dataPointIndex];

        const primaryLabel = branchName
          ? w.globals.seriesNames[seriesIndex]
          : 'Collected';

        return renderToString(
          <div className="w-40">
            <div>
              <div className="flex flex-col gap-1 bg-darkGrey px-3 py-2 text-sm font-medium text-white">
                <div className="flex w-full items-center gap-2">
                  <div
                    className="size-2.5 min-h-2.5 min-w-2.5 rounded-full"
                    style={{ backgroundColor: seriesColor }}
                  />
                  <div className="max-w-28 truncate">{seriesName}</div>
                </div>
                <div className="text-sm text-grey80">
                  {formatISODate({
                    date: new Date(xAxisValues[dataPointIndex]),
                    format: 'DD MMM'
                  })}
                </div>
              </div>
            </div>
            <div className="bg-content py-2 text-sm">
              <div className="flex items-center justify-between gap-2  px-3 ">
                <div className="flex items-center justify-center gap-2 text-grey80">
                  {primaryLabel}
                </div>
                <div className="text-white">₹ {primaryValue}</div>
              </div>
              {(secondaryValue || secondaryValue === 0) && (
                <div className="flex items-center justify-between gap-2 px-3 ">
                  <div className="flex items-center justify-center gap-2 text-grey80">
                    Due
                  </div>
                  <div className="text-white">₹ {secondaryValue}</div>
                </div>
              )}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className={className}>
      {title && <h1 className="text-lg font-bold text-content">{title}</h1>}
      <ReactApexChart
        options={options}
        series={seriesData}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
