import { useEffect, useState } from 'react';

import { CustomPaginationProps } from 'types/pagination';
import Input from '../input/Input';

const PageLink = ({
  totalCount,
  limit,
  page,
  setPage
}: CustomPaginationProps) => {
  const pageCount = Math.ceil(totalCount / limit);

  const [pageInput, setPageInput] = useState<number>(page);

  const handleInputChange = (e: any) => {
    if (e.target.value <= pageCount && e.target.value !== 0)
      setPageInput(Number(e.target.value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (pageInput >= 1) setPage(pageInput);
    }
  };

  useEffect(() => {
    setPageInput(page);
  }, [page]);

  return (
    <div className="flex items-center justify-center gap-1 text-sm font-medium">
      <div>Go to page</div>
      <Input
        value={pageInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="w-15 !p-1"
        inputSize="small"
        inputStyle="!bg-white text-center !pl-2"
      />
    </div>
  );
};

export default PageLink;
