import { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import useOnClickOutside from '@utils/hooks';
import Button from '@components/button/Button';
import {
  DropdownOption,
  MultiSelectDropdownWithApplyProps
} from 'types/dropdown';

import Icon from '../icon/Icon';
import Checkbox from '../checkbox/Checkbox';
import PlusNChipList from '../plus-n-chip-list/PlusNChipList';

const MultiSelectDropdownWithApplyButton = ({
  label,
  options,
  onApply,
  selectedOptions,
  className,
  displayChipsCount = 2,
  plusMoreClickRequired = true,
  isLoading,
  chipCloseRequired,
  minSelectOptionsCount,
  maxSelectOptionsCount = 0,
  customOptionLabel,
  customOption
}: MultiSelectDropdownWithApplyProps) => {
  const ref = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] =
    useState<DropdownOption[]>(selectedOptions);
  const [isCustomOptionChecked, setCustomOptionChecked] =
    useState<boolean>(true);
  const [maxSelectOptionsCountExceeded, setMaxSelectOptionsCountExceeded] =
    useState<boolean>(false);

  const findIndex = (item: DropdownOption) =>
    selectedData.findIndex((option) => option.id === item.id);

  const onClickCustomOption = (isChecked: boolean) => {
    setMaxSelectOptionsCountExceeded(false);
    if (isChecked) {
      setCustomOptionChecked(true);
      setSelectedData([customOption]);
    } else {
      setSelectedData([]);
      setCustomOptionChecked(false);
    }
  };

  const handleOnChange = (
    element: DropdownOption,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedState = event.target.checked;
    setMaxSelectOptionsCountExceeded(false);
    setCustomOptionChecked(false);
    const filteredData = selectedData.filter(
      (item) => item.id !== customOption.id
    );
    const index = findIndex(element);
    let updatedOptions: DropdownOption[] = [...filteredData];

    if (checkedState) {
      if (index < 0 && selectedData.length < maxSelectOptionsCount) {
        updatedOptions.push(element);
      } else if (selectedData.length >= maxSelectOptionsCount) {
        setMaxSelectOptionsCountExceeded(true);
      }
    } else if (index >= 0) {
      updatedOptions = updatedOptions.filter(
        (option) => option.id !== element.id
      );
    }

    setSelectedData(updatedOptions);
  };

  const handleOnRemove = (item: DropdownOption) => {
    const index = findIndex(item);
    let updatedOptions: DropdownOption[];
    if (index >= 0) {
      updatedOptions = selectedData.filter((option) => option.id !== item.id);
      setSelectedData(updatedOptions);
    }
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setSelectedData(selectedOptions);
    if (
      selectedOptions.length === 1 &&
      selectedOptions[0].id === customOption.id
    ) {
      setCustomOptionChecked(true);
    }
  };

  const handleApplyClick = () => {
    onApply(selectedData);
    setIsDropdownOpen(false);
  };

  const handleCustomOption = () => {
    if (
      selectedOptions.length === 1 &&
      selectedOptions[0].id === customOption.id
    ) {
      setCustomOptionChecked(true);
      setSelectedData([customOption]);
    } else {
      setCustomOptionChecked(false);
    }
  };

  const handleCancelClick = () => {
    setIsDropdownOpen(false);
    setSelectedData(selectedOptions);
    handleCustomOption();
  };

  useOnClickOutside(ref, () => {
    handleCancelClick();
  });

  useEffect(() => {
    if (options.length > 0) {
      handleCustomOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isDropdownOpen) {
      setMaxSelectOptionsCountExceeded(false);
    }
  }, [isDropdownOpen]);

  const renderDropdownLabel = () => {
    if (isCustomOptionChecked)
      return <span className="text-subText">{customOptionLabel}</span>;
    if (selectedData.length)
      return (
        <PlusNChipList
          selectedOptions={selectedData}
          handleOnRemove={handleOnRemove}
          plusMoreClickRequired={plusMoreClickRequired}
          displayChipsCount={displayChipsCount}
          chipClassName="bg-white"
          tooltipChipClassName="bg-grey10"
          isCloseIconRequired={chipCloseRequired}
        />
      );
    return label;
  };

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div ref={ref} className={`relative w-full ${className || ''}`}>
      <div
        role="presentation"
        onClick={handleDropdownClick}
        className="relative flex h-12.5 cursor-pointer items-center rounded-xl border bg-bgGrey20 px-4 hover:border-grey"
      >
        {renderDropdownLabel()}
        <Icon
          name="up"
          className={`absolute right-4 ${isDropdownOpen ? '' : 'rotate-180'}`}
        />
      </div>
      {isDropdownOpen && (
        <div className="absolute z-10 mt-1 w-full rounded-xl border border-whiteBorder bg-white p-4 shadow-multiSelectDropdownShadow">
          {options.length > 0 ? (
            <>
              <div className="relative mt-3 rounded border border-grey30 bg-white py-2 shadow-dropdownOptionsShadow">
                <div className="border-b border-b-grey30 py-2">
                  <Checkbox
                    handleChange={(e) => onClickCustomOption(e.target.checked)}
                    label={`Top ${maxSelectOptionsCount} branches`}
                    className="px-4"
                    labelStyle="text-base !text-content !font-medium"
                    checked={isCustomOptionChecked}
                  />
                </div>

                <div className="custom-scroll max-h-180 overflow-y-auto px-4">
                  {options.map((item) => (
                    <Checkbox
                      label={item.label}
                      key={item.value}
                      handleChange={(e) => handleOnChange(item, e)}
                      checked={findIndex(item) >= 0}
                      className="py-2"
                      labelStyle="!text-content text-base font-normal"
                    />
                  ))}
                </div>
                {maxSelectOptionsCountExceeded && (
                  <div className="absolute -bottom-6 left-0 text-xs text-error">
                    {`Maximum selected options should be ${maxSelectOptionsCount}`}
                  </div>
                )}
              </div>

              <div className="mt-6 flex justify-end gap-2">
                <Button
                  label="Apply"
                  handleClick={handleApplyClick}
                  disabled={selectedData.length < (minSelectOptionsCount ?? 0)}
                />
                <Button
                  label="Cancel"
                  variant="secondary"
                  handleClick={handleCancelClick}
                />
              </div>
            </>
          ) : (
            <div className="mt-3 flex h-14 items-center p-4 text-base font-normal italic text-content shadow-multiSelectDropdownShadow">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdownWithApplyButton;
